<template>
  <div>
    <b-row style="padding: 1rem">
      <b-col
        :md="
          item.tipo_grafico === 0 || [1, 3, 5, 6].includes(item.tipo_tabela)
            ? '12'
            : '6'
        "
      >
        <h5 style="margin-top: 0.5rem">{{ item.titulo }}</h5>
        <p
          style="font-size: 12.5px; margin-top: 1rem"
          v-if="item.id_campo !== 14"
        >
          {{ item.descricao }}
        </p>

        <div
          v-if="
            item.tipo_tabela === 1 &&
            (item.resposta_cliente === 1 || item.resposta_cliente === 3)
          "
          :class="
            item.resposta_cliente === 1
              ? 'approved'
              : item.resposta_cliente === 3
              ? 'refused'
              : ''
          "
        >
          <span v-if="item.resposta_cliente === 1">
            OPÇÃO
            {{
              item.opcao_selecionada_necessario
                ? 'VALOR SUGERIDO'
                : item.diagnostico_produto_item_opcoes.findIndex(
                    (option) =>
                      option.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id
                  ) + 1
            }}
            - APROVADA
          </span>

          <span v-if="item.resposta_cliente === 3"> PROPOSTAS RECUSADAS </span>
        </div>

        <ContactDataTable
          :diagnosis="diagnosis"
          v-if="
            ([5, 6].includes(diagnosisProduct.produto_diagnostico_id) ||
              ['Seguro patrimonial'].includes(item.grupo) ||
              (item.id_campo === 14 &&
                diagnosisProduct.produto_diagnostico_id !== 3)) &&
            item.grupo !== 'Direcionamento ao especialista'
          "
        />

        <ContactDataHealthTable
          :diagnosis="diagnosis"
          v-if="item.grupo === 'Saúde'"
        />

        <ConsultantDataTable
          :item="item"
          v-if="
            [5, 6].includes(diagnosisProduct.produto_diagnostico_id) &&
            item.grupo !== 'Direcionamento ao especialista'
          "
        />

        <div v-if="item.tipo_grafico !== 0" style="overflow-x: auto">
          <strong v-if="item.utiliza_opcoes" style="color: var(--primary)">
            Preencha os campos abaixo com os dados e valores do orçamento:
          </strong>

          <strong v-if="item.aguardando_resposta" style="color: var(--primary)">
            SELECIONE ABAIXO A OPÇÃO QUE O CLIENTE APROVOU:
          </strong>

          <span
            v-if="false"
            style="
              background: var(--red-light);
              position: absolute;
              left: 1rem;
              top: 10rem;
              width: 13rem;
              padding: 0.5rem;
              border-radius: 5px;
              color: var(--white);
            "
          >
            Por favor, selecione uma opção para continuar.
          </span>

          <table
            style="margin-top: 2rem"
            class="table"
            v-if="item.exibir_tabela && item.tipo_tabela === 1"
          >
            <tr>
              <th>ITENS</th>

              <th colspan="2" style="background: #e8e8ea9f" />

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  'var(--primary)'
                };`"
                colspan="2"
                class="text-center"
                v-if="currentOption"
              >
                OPÇÃO {{ currentOptionIndex + 1 }}
              </th>
            </tr>

            <tr>
              <th></th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR NECESSÁRIO
              </th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR ATUAL
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  'var(--primary)'
                };`"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <b-button
                    size="sm"
                    variant="light"
                    class="float-left"
                    style="background-color: #eff2f7"
                    :disabled="!hasPrevOption"
                    @click="handlePrevOption()"
                  >
                    <i class="fas fa-arrow-left" />
                  </b-button>

                  <b-form-radio
                    v-model="item.opcao_selecionada_necessario"
                    style="margin-right: 1rem"
                    :name="`item-${item.diagnostico_produto_item_id}-opcao-selecionada1`"
                    :value="true"
                    @change="() => handleChangeOpcaoSelecionada(true)"
                  >
                    VALOR SUGERIDO
                  </b-form-radio>

                  <div />
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  'var(--primary)'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <b-form-radio
                    v-model="item.opcao_selecionada_id"
                    style="margin-right: 1rem"
                    :name="`item-${item.diagnostico_produto_item_id}-opcao-selecionada2`"
                    :value="currentOption.diagnostico_produto_item_opcao_id"
                    @change="() => handleChangeOpcaoSelecionada()"
                  >
                    VALOR AJUSTADO
                  </b-form-radio>

                  <b-button
                    size="sm"
                    variant="light"
                    class="float-right"
                    style="background-color: #eff2f7"
                    :disabled="!hasNextOption"
                    @click="handleNextOption()"
                  >
                    <i class="fas fa-arrow-right" />
                  </b-button>
                </div>
              </th>
            </tr>

            <tr>
              <td>
                <span> 1. Capital Segurado </span>
              </td>
              <td :style="`background: #e8e8ea9f;`">
                R$ {{ item.necessidades | decimal }}
              </td>

              <td :style="`background: #e8e8ea9f;`">
                R$ {{ item.valor_atual | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                R$ {{ currentOption.capital_segurado_sugerido | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.capital_segurado"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.capital_segurado | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Seguradora Sugerida</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  {{ seguroPrincipal.seguradora }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.seguradora_sugerido"
                  :options="insurersOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.seguradora_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.seguradora"
                  :options="insurersOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.seguradora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Nome da solução</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  {{ seguroPrincipal.tipo_seguro }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.solucao_sugerido"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.solucao_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.solucao"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.solucao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Período de cobertura</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_cobertura_sugerido"
                  :options="
                    isGestaoRiscoMorte
                      ? periodoCoberturaMorte
                      : isInvalidez
                      ? periodoCoberturaInvalidez
                      : periodoCoberturaVida
                  "
                  @change="
                    (value) => handleChangePeriodoCoberturaSugerido(value)
                  "
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  {{
                    currentOption.periodo_cobertura_sugerido | periodoCobertura
                  }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_cobertura"
                  :options="
                    isGestaoRiscoMorte
                      ? periodoCoberturaMorte
                      : periodoCoberturaVida
                  "
                  @change="(value) => handleChangePeriodoCobertura(value)"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  {{ currentOption.periodo_cobertura | periodoCobertura }}
                </span>
              </td>
            </tr>

            <tr v-if="isGestaoRiscoMorte">
              <td>
                <span>5. Período de pagamento</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_pagamento_sugerido"
                  :options="periodoCoberturaMorte"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{
                  currentOption.periodo_pagamento_sugerido
                }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_pagamento"
                  :options="periodoCoberturaMorte"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.periodo_pagamento }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Prêmio mensal</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  R$ {{ seguroPrincipal.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_mensal_sugerido"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.premio_mensal_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_mensal"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.premio_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Prêmio anual</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  R$ {{ (seguroPrincipal.valor_mensal * 12) | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_anual_sugerido"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.premio_anual_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_anual"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.premio_anual | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div v-if="currentOption.download_url">
                  <div>{{ currentOption.nome_original }}</div>
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>
                </div>
              </td>

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div v-if="currentOption.download_url_2">
                  <div>{{ currentOption.nome_original_2 }}</div>
                  <a :href="currentOption.download_url_2" target="_blank">
                    Visualizar
                  </a>
                </div>
              </td>
            </tr>
          </table>

          <div
            v-if="
              item.exibir_tabela &&
              item.tipo_tabela === 1 &&
              item.id_campo === 1 &&
              false
            "
          >
            <table style="margin-top: 2rem" class="table">
              <tr>
                <th>ITENS</th>

                <th colspan="2" style="background: #e8e8ea9f" />

                <th
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    'var(--primary)'
                  };`"
                  colspan="2"
                  class="text-center"
                  v-if="currentOption"
                >
                  OPÇÃO {{ currentOptionIndex + 1 }}
                </th>
              </tr>

              <tr>
                <th>VIDA</th>

                <th
                  style="background: #e8e8ea9f; color: #495057"
                  class="text-center"
                >
                  VALOR NECESSÁRIO
                </th>

                <th
                  style="background: #e8e8ea9f; color: #495057"
                  class="text-center"
                >
                  VALOR ATUAL
                </th>

                <th
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario &&
                    'var(--primary)'
                  };`"
                  class="text-center"
                  v-if="currentOption"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <b-button
                      size="sm"
                      variant="light"
                      class="float-left"
                      style="background-color: #eff2f7"
                      :disabled="!hasPrevOption"
                      @click="handlePrevOption()"
                    >
                      <i class="fas fa-arrow-left" />
                    </b-button>

                    <b-form-radio
                      v-model="item.opcao_selecionada_necessario"
                      style="margin-right: 1rem"
                      :name="`item-${item.diagnostico_produto_item_id}-opcao-selecionada3`"
                      :value="true"
                      @change="() => handleChangeOpcaoSelecionada(true)"
                    >
                      VALOR SUGERIDO
                    </b-form-radio>

                    <div />
                  </div>
                </th>

                <th
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario &&
                    'var(--primary)'
                  };`"
                  class="text-center"
                  v-if="currentOption"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div />

                    <b-form-radio
                      v-model="item.opcao_selecionada_id"
                      style="margin-right: 1rem"
                      :name="`item-${item.diagnostico_produto_item_id}-opcao-selecionada4`"
                      :value="currentOption.diagnostico_produto_item_opcao_id"
                      @change="() => handleChangeOpcaoSelecionada()"
                    >
                      VALOR AJUSTADO
                    </b-form-radio>

                    <b-button
                      size="sm"
                      variant="light"
                      class="float-right"
                      style="background-color: #eff2f7"
                      :disabled="!hasNextOption"
                      @click="handleNextOption()"
                    >
                      <i class="fas fa-arrow-right" />
                    </b-button>
                  </div>
                </th>
              </tr>

              <tr>
                <td>
                  <span> 1. Capital Segurado </span>
                </td>
                <td :style="`background: #e8e8ea9f;`">
                  R$ {{ item.necessidades | decimal }}
                </td>

                <td :style="`background: #e8e8ea9f;`">
                  R$ {{ item.valor_atual | decimal }}
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  R$ {{ currentOption.capital_segurado_sugerido | decimal }}
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.capital_segurado"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.capital_segurado | decimal }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>2. Seguradora Sugerida</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroVida">
                    {{ seguroVida.seguradora }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.seguradora_sugerido"
                    :options="insurersOptions"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.seguradora_sugerido }}</span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.seguradora"
                    :options="insurersOptions"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.seguradora }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>3. Nome da solução</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroVida">
                    {{ seguroVida.tipo_seguro }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-input
                    type="text"
                    v-model="currentOption.solucao_sugerido"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.solucao_sugerido }}</span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-input
                    type="text"
                    v-model="currentOption.solucao"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.solucao }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>4. Período de cobertura</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`"></td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.periodo_cobertura_sugerido"
                    :options="
                      isGestaoRiscoMorte
                        ? periodoCoberturaMorte
                        : periodoCoberturaVida
                    "
                    @change="
                      (value) => handleChangePeriodoCoberturaSugerido(value)
                    "
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    {{
                      currentOption.periodo_cobertura_sugerido
                        | periodoCobertura
                    }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.periodo_cobertura"
                    :options="
                      isGestaoRiscoMorte
                        ? periodoCoberturaMorte
                        : periodoCoberturaVida
                    "
                    @change="(value) => handleChangePeriodoCobertura(value)"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    {{ currentOption.periodo_cobertura | periodoCobertura }}
                  </span>
                </td>
              </tr>

              <tr v-if="isGestaoRiscoMorte">
                <td>
                  <span>5. Período de pagamento</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`"></td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.periodo_pagamento_sugerido"
                    :options="periodoCoberturaMorte"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{
                    currentOption.periodo_pagamento_sugerido
                  }}</span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.periodo_pagamento"
                    :options="periodoCoberturaMorte"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.periodo_pagamento }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>6. Prêmio mensal</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroVida">
                    R$ {{ seguroVida.valor_mensal | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.premio_mensal_sugerido"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.premio_mensal_sugerido | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.premio_mensal"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.premio_mensal | decimal }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>7. Prêmio anual</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroVida">
                    R$ {{ (seguroVida.valor_mensal * 12) | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.premio_anual_sugerido"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.premio_anual_sugerido | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id &&
                    !item.opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.premio_anual"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.premio_anual | decimal }}
                  </span>
                </td>
              </tr>

              <tr>
                <td></td>

                <td style="background: #e8e8ea9f" colspan="2" />

                <td style="background: #e8e8ea9f" v-if="currentOption">
                  <div v-if="currentOption.download_url">
                    <div>{{ currentOption.nome_original }}</div>
                    <a :href="currentOption.download_url" target="_blank">
                      Visualizar
                    </a>
                  </div>
                </td>

                <td style="background: #e8e8ea9f" v-if="currentOption">
                  <div v-if="currentOption.download_url_2">
                    <div>{{ currentOption.nome_original_2 }}</div>
                    <a :href="currentOption.download_url_2" target="_blank">
                      Visualizar
                    </a>
                  </div>
                </td>
              </tr>
            </table>

            <table style="margin-top: 2rem" class="table">
              <tr>
                <th>ITENS</th>

                <th colspan="2" style="background: #e8e8ea9f" />

                <th
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    'var(--primary)'
                  };`"
                  colspan="2"
                  v-if="currentOption"
                >
                  OPÇÃO {{ currentOptionIndex + 1 }}
                </th>
              </tr>

              <tr>
                <th>FUNERAL</th>

                <th
                  style="background: #e8e8ea9f; color: #495057"
                  class="text-center"
                >
                  VALOR NECESSÁRIO
                </th>

                <th
                  style="background: #e8e8ea9f; color: #495057"
                  class="text-center"
                >
                  VALOR ATUAL
                </th>

                <th
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario &&
                    'var(--primary)'
                  };`"
                  class="text-center"
                  v-if="currentOption"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <b-button
                      size="sm"
                      variant="light"
                      class="float-left"
                      style="background-color: #eff2f7"
                      :disabled="!hasPrevOption"
                      @click="handlePrevOption()"
                    >
                      <i class="fas fa-arrow-left" />
                    </b-button>

                    <b-form-radio
                      v-model="item.fun_opcao_selecionada_necessario"
                      style="margin-right: 1rem"
                      :name="`item-${item.diagnostico_produto_item_id}-opcao-selecionada3`"
                      :value="true"
                      @change="() => handleChangeOpcaoSelecionadaFuneral(true)"
                    >
                      VALOR SUGERIDO
                    </b-form-radio>

                    <div />
                  </div>
                </th>

                <th
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario &&
                    'var(--primary)'
                  };`"
                  class="text-center"
                  v-if="currentOption"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div />

                    <b-form-radio
                      v-model="item.fun_opcao_selecionada_id"
                      style="margin-right: 1rem"
                      :name="`item-${item.diagnostico_produto_item_id}-opcao-selecionada4`"
                      :value="currentOption.diagnostico_produto_item_opcao_id"
                      @change="() => handleChangeOpcaoSelecionadaFuneral()"
                    >
                      VALOR AJUSTADO
                    </b-form-radio>

                    <b-button
                      size="sm"
                      variant="light"
                      class="float-right"
                      style="background-color: #eff2f7"
                      :disabled="!hasNextOption"
                      @click="handleNextOption()"
                    >
                      <i class="fas fa-arrow-right" />
                    </b-button>
                  </div>
                </th>
              </tr>

              <tr>
                <td>
                  <span> 1. Capital Segurado </span>
                </td>
                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="itemFuneral.necessidades">
                    R$ {{ itemFuneral.necessidades | decimal }}
                  </span>
                </td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="itemFuneral.valor_atual">
                    R$ {{ itemFuneral.valor_atual | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  R$ {{ currentOption.fun_capital_segurado_sugerido | decimal }}
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.fun_capital_segurado"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.fun_capital_segurado | decimal }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>2. Seguradora Sugerida</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroFuneral">
                    {{ seguroFuneral.seguradora }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.fun_seguradora_sugerido"
                    :options="insurersOptions"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{
                    currentOption.fun_seguradora_sugerido
                  }}</span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.fun_seguradora"
                    :options="insurersOptions"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.fun_seguradora }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>3. Nome da solução</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroFuneral">
                    {{ seguroFuneral.tipo_seguro }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-input
                    type="text"
                    v-model="currentOption.fun_solucao_sugerido"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.fun_solucao_sugerido }}</span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-input
                    type="text"
                    v-model="currentOption.fun_solucao"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.fun_solucao }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>4. Período de cobertura</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`"></td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.fun_periodo_cobertura_sugerido"
                    :options="periodoCoberturaVida"
                    @change="
                      (value) => handleChangeFunPeriodoCoberturaSugerido(value)
                    "
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    {{
                      currentOption.fun_periodo_cobertura_sugerido
                        | periodoCobertura
                    }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.fun_periodo_cobertura"
                    :options="periodoCoberturaVida"
                    @change="(value) => handleChangeFunPeriodoCobertura(value)"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    {{ currentOption.fun_periodo_cobertura | periodoCobertura }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>5. Período de pagamento</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`"></td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.fun_periodo_pagamento_sugerido"
                    :options="periodoCoberturaVida"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{
                    currentOption.fun_periodo_pagamento_sugerido
                  }}</span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <b-form-select
                    v-model="currentOption.fun_periodo_pagamento"
                    :options="periodoCoberturaVida"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>{{ currentOption.fun_periodo_pagamento }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>6. Prêmio mensal</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroFuneral">
                    R$ {{ seguroFuneral.valor_mensal | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.fun_premio_mensal_sugerido"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.fun_premio_mensal_sugerido | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.fun_premio_mensal"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.fun_premio_mensal | decimal }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  <span>7. Prêmio anual</span>
                </td>
                <td :style="`background: #e8e8ea9f;`"></td>

                <td :style="`background: #e8e8ea9f;`">
                  <span v-if="seguroFuneral">
                    R$ {{ (seguroFuneral.valor_mensal * 12) | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.fun_premio_anual_sugerido"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.fun_premio_anual_sugerido | decimal }}
                  </span>
                </td>

                <td
                  :style="`background: #e8e8ea9f; color: ${
                    currentOption &&
                    currentOption.diagnostico_produto_item_opcao_id &&
                    currentOption.diagnostico_produto_item_opcao_id ===
                      item.fun_opcao_selecionada_id &&
                    !item.fun_opcao_selecionada_necessario
                      ? '#1D954B'
                      : '#495057'
                  };`"
                  v-if="currentOption"
                >
                  <money
                    class="form-control"
                    v-bind="moneyMask"
                    v-model="currentOption.fun_premio_anual"
                    v-if="!item.aguardando_resposta"
                  />
                  <span v-else>
                    R$ {{ currentOption.fun_premio_anual | decimal }}
                  </span>
                </td>
              </tr>

              <tr>
                <td></td>

                <td style="background: #e8e8ea9f" colspan="2" />

                <td style="background: #e8e8ea9f" v-if="currentOption">
                  <div v-if="currentOption.download_url">
                    <div>{{ currentOption.nome_original }}</div>
                    <a :href="currentOption.download_url" target="_blank">
                      Visualizar
                    </a>
                  </div>
                </td>

                <td style="background: #e8e8ea9f" v-if="currentOption">
                  <div v-if="currentOption.download_url_2">
                    <div>{{ currentOption.nome_original_2 }}</div>
                    <a :href="currentOption.download_url_2" target="_blank">
                      Visualizar
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <table
            style="margin-top: 2rem"
            class="table"
            v-if="item.tipo_tabela === 3"
          >
            <tr>
              <th></th>

              <th :style="`background: #e8e8ea9f;`" colspan="2">
                <div class="d-flex align-items-center justify-content-center">
                  PLANO ATUAL
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  'var(--primary)'
                };`"
                colspan="2"
                class="text-center"
                v-if="currentOption"
              >
                OPÇÃO {{ currentOptionIndex + 1 }}
              </th>
            </tr>

            <tr>
              <th></th>

              <th :style="`background: #e8e8ea9f;`" class="text-center">
                <div class="d-flex align-items-center justify-content-between">
                  <b-button
                    size="sm"
                    variant="light"
                    class="float-left"
                    style="background-color: #eff2f7"
                    :disabled="!hasPrevOption"
                    @click="handlePrevOption()"
                    v-if="currentOption"
                  >
                    <i class="fas fa-arrow-left" />
                  </b-button>

                  TITULAR

                  <div />
                </div>
              </th>

              <th :style="`background: #e8e8ea9f;`" class="text-center">
                DEPENDENTES NÃO INCLUSOS
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes &&
                  'var(--primary)'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                <b-form-radio
                  v-model="opcaoSaudeTitular"
                  :value="true"
                  @change="() => handleChangeOpcaoSelecionadaSaude(false)"
                >
                  TITULAR
                </b-form-radio>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes &&
                  'var(--primary)'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <b-form-radio
                    v-model="opcaoSaudeDependentes"
                    :value="true"
                    @change="() => handleChangeOpcaoSelecionadaSaude(true)"
                  >
                    DEPENDENTES NÃO INCLUSOS
                  </b-form-radio>

                  <b-button
                    size="sm"
                    variant="light"
                    class="float-right"
                    style="background-color: #eff2f7"
                    :disabled="!hasNextOption"
                    @click="handleNextOption()"
                  >
                    <i class="fas fa-arrow-right" />
                  </b-button>
                </div>
              </th>
            </tr>

            <tr>
              <td>
                <span>1. Pessoa física ou jurídica</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.tipo_pessoa }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.tipo_pessoa }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo_pessoa"
                  :options="tipoPessoaSaudeOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.tipo_pessoa }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_tipo_pessoa"
                  :options="tipoPessoaSaudeOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_tipo_pessoa }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Operadora</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.operadora }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.operadora }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.operadora"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.operadora }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_operadora"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_operadora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Estado</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.estado }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.estado }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.estado"
                  :options="stateOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.estado }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_estado"
                  :options="stateOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_estado }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Cidade</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.cidade }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.cidade }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.cidade"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.cidade }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_cidade"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_cidade }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>5. Nome de produto</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.nome_produto }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.nome_produto }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.nome_produto"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.nome_produto }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_nome_produto"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_nome_produto }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Tipo de cobertura</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.tipo_cobertura }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.tipo_cobertura }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo_cobertura"
                  :options="tipoCoberturaOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.tipo_cobertura }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_tipo_cobertura"
                  :options="tipoCoberturaOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_tipo_cobertura }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Acomodação</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.acomodacao }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.acomodacao }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.acomodacao"
                  :options="acomodacaoOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.acomodacao }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_acomodacao"
                  :options="acomodacaoOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_acomodacao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>8. Hospital de preferência</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.possui_preferencia }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.possui_preferencia }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.possui_preferencia"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.possui_preferencia }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_possui_preferencia"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.dep_possui_preferencia }}</span>
              </td>
            </tr>

            <tr>
              <td>9. Vidas</td>
              <td colspan="4" style="background-color: #e8e8ea9f" />
            </tr>

            <tr v-for="(faixa, index) in faixasIdadeVidasSaude" :key="index">
              <td class="pl-4">{{ faixa.title }}</td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ faixa.quantidadeTitular || '-' }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ faixa.quantidadeConjuge || '-' }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                {{ faixa.quantidadeTitular || '-' }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                {{ faixa.quantidadeConjuge || '-' }}
              </td>
            </tr>

            <tr>
              <td>
                <span>10. Valor mensal</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$ {{ diagnosis.pessoa.saude.valor_mensal | decimal }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  R$ {{ saudeDependente.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_mensal"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.dep_valor_mensal"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.dep_valor_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>11. Valor de reembolso</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$ {{ diagnosis.pessoa.saude.valor_reembolso | decimal }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  R$ {{ saudeDependente.valor_reembolso | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_reembolso"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.valor_reembolso | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.dep_valor_reembolso"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.dep_valor_reembolso | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>12. Co-participação</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.coparticipacao ? 'Sim' : 'Não' }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.coparticipacao ? 'Sim' : 'Não' }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.coparticipacao"
                  :options="yesOrNotOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  {{ currentOption.coparticipacao ? 'Sim' : 'Não' }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_coparticipacao"
                  :options="yesOrNotOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  {{ currentOption.dep_coparticipacao ? 'Sim' : 'Não' }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>13. Validade da proposta</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ item.diagnostico_produto_item_tabela_itens[9].coluna_valor }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ item.diagnostico_produto_item_tabela_itens[9].coluna_valor }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                {{ currentOption.validade_proposta }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                {{ currentOption.dep_validade_proposta }}
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div v-if="currentOption.download_url">
                  <div>{{ currentOption.nome_original }}</div>
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>
                </div>
              </td>

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div v-if="currentOption.download_url_2">
                  <div>{{ currentOption.nome_original_2 }}</div>
                  <a :href="currentOption.download_url_2" target="_blank">
                    Visualizar
                  </a>
                </div>
              </td>
            </tr>
          </table>

          <table
            style="margin-top: 2rem"
            class="table"
            v-if="item.tipo_tabela === 5"
          >
            <tr>
              <th></th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  'var(--primary)'
                };`"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                  v-if="currentOption"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                <div class="d-flex align-items-center justify-content-center">
                  ATUAL
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  'var(--primary)'
                };`"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <div class="d-flex align-items-center">
                    <b-button
                      size="sm"
                      variant="light"
                      class="float-right ml-3"
                      style="background-color: #eff2f7"
                      :disabled="!hasNextOption"
                      @click="handleNextOption()"
                    >
                      <i class="fas fa-arrow-right" />
                    </b-button>
                  </div>
                </div>
              </th>
            </tr>

            <tr>
              <td>
                <span>1. Nome do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[0].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.nome_bem"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.nome_bem }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Tipo</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[1].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo"
                  :options="tipoBemOptions"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.tipo }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Valor do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[2].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_bem"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else> R$ {{ currentOption.valor_bem | decimal }} </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Valor financiado</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[3].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_financiado"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.valor_financiado | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>5. Parcela mensal</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[4].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_mensal"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.valor_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Quantas parcelas faltam</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[5].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-mask="'###'"
                  v-model="currentOption.parcelas_restantes"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.parcelas_restantes }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Forma de construção</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[6].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.forma_construcao"
                  :options="formaConstrucaoOptions"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.forma_construcao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>8. Taxa de correção</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[7].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.taxa_correcao"
                  :options="indiceCorrecaoOptions"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.taxa_correcao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>9. Operadora</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[8].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.operadora"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.operadora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>10. Valor de entrada ou lance</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[9].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_entrada"
                  v-if="!isProdutoPatrimonio && !item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.valor_entrada }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>11. Validade da proposta</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[10].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                {{ currentOption.validade_proposta }}
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div v-if="currentOption.download_url">
                  <div>{{ currentOption.nome_original }}</div>
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>
                </div>
              </td>
            </tr>
          </table>

          <table
            style="margin-top: 2rem"
            class="table"
            v-if="item.tipo_tabela === 6"
          >
            <tr>
              <th></th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  'var(--primary)'
                };`"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                  v-if="currentOption"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                <div class="d-flex align-items-center justify-content-center">
                  ATUAL
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  'var(--primary)'
                };`"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <div class="d-flex align-items-center">
                    <b-button
                      size="sm"
                      variant="light"
                      class="float-right ml-3"
                      style="background-color: #eff2f7"
                      :disabled="!hasNextOption"
                      @click="handleNextOption()"
                    >
                      <i class="fas fa-arrow-right" />
                    </b-button>
                  </div>
                </div>
              </th>
            </tr>

            <tr>
              <td>
                <span>1. Nome do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[0].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.nome_bem"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.nome_bem }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Tipo</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[1].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo"
                  :options="dreamTypeOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.tipo }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Valor do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[2].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_bem"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else> R$ {{ currentOption.valor_bem | decimal }} </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Valor de entrada</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[3].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_entrada"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.valor_entrada | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>5. Parcela mensal</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[4].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_mensal"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>
                  R$ {{ currentOption.valor_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Para quando</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[5].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.para_quando"
                  :options="dreamYearOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.para_quando }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Validade da proposta</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[6].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                {{ currentOption.validade_proposta }}
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div v-if="currentOption.download_url">
                  <div>{{ currentOption.nome_original }}</div>
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-col>

      <StepItemGraph :item="item" :diagnosis="diagnosis" />
    </b-row>

    <b-row>
      <b-col
        md="12"
        class="d-flex justify-content-between align-items-center"
        v-if="!item.utiliza_opcoes || item.aguardando_resposta"
      >
        <b-button style="background: transparent; border: none">
          <span
            style="color: var(--primary); font-weight: 500; font-size: 14px"
          >
            Resposta do cliente
          </span>
        </b-button>

        <b-form-select
          :style="`width: 30%; border-color: ${
            !item.resposta_cliente
              ? 'var(--blue)'
              : item.resposta_cliente == 1
              ? 'var(--green-later-leaf)'
              : [2, 4].includes(item.resposta_cliente)
              ? 'var(--yellow-light)'
              : item.resposta_cliente == 3 && 'var(--red-light)'
          }; color: ${
            !item.resposta_cliente
              ? 'var(--blue)'
              : item.resposta_cliente == 1
              ? 'var(--green-dark)'
              : [2, 4].includes(item.resposta_cliente)
              ? 'var(--crete)'
              : item.resposta_cliente == 3 && 'var(--roof-terracotta)'
          }; background-color: ${
            !item.resposta_cliente
              ? 'var(--white)'
              : item.resposta_cliente == 1
              ? 'var(--green-light)'
              : [2, 4].includes(item.resposta_cliente)
              ? 'var(--yellow-light-2)'
              : item.resposta_cliente == 3 && 'var(--pink-light)'
          }`"
          v-model="item.resposta_cliente"
          :options="statusStudy"
          @change="updateCustomerResponse()"
        />
      </b-col>

      <b-col cols="12" class="d-flex justify-content-end" v-else>
        <ButtonEditComment
          class="mt-3"
          icon="plus"
          :title="`${
            item.diagnostico_comentario &&
            item.diagnostico_comentario.comentario
              ? 'EDITAR'
              : 'ADICIONAR'
          } COMENTÁRIO`"
          :openModalEditComment="() => openModalAddEditComment()"
        />
      </b-col>

      <b-col md="6" class="d-flex justify-content-between align-items-center">
        <div>
          <b-button-group
            class="mt-3"
            style="padding: 6px 0"
            @click="viewSpecialistComment()"
            v-if="
              item.diagnostico_comentario_especialista &&
              item.diagnostico_comentario_especialista.comentario
            "
          >
            <b-button
              style="
                background: var(--border-blue-light);
                border-color: var(--border-blue-light);
              "
            >
              <i class="fas fa-eye" style="font-size: 1rem"></i>
            </b-button>

            <b-button variant="primary">
              Ver comentário do especialista
            </b-button>
          </b-button-group>
        </div>
      </b-col>
    </b-row>

    <ModalViewComment
      title="Comentário do especialista"
      :isOpen="isOpenModalViewComment"
      :diagnosisComment="item.diagnostico_comentario_especialista"
      @closeModal="closeModalViewComment()"
    />

    <ModalAddEditComment
      :isOpen="isOpenModalAddEditComment"
      :data="modalData"
      @onSaveComment="(diagnosisComment) => onSaveComment(diagnosisComment)"
      @closeModal="closeModalAddEditComment()"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import { Money } from 'v-money';
import { mapGetters } from 'vuex';

import ButtonEditComment from '@/components/Buttons/ButtonEditComment';
import StepItemGraph from './StepViewItemGraph';
import ContactDataTable from '@/components/common/ContactDataTable';
import ContactDataHealthTable from '@/components/common/ContactDataHealthTable';
import ConsultantDataTable from '@/components/common/ConsultantDataTable';

import ModalAddEditComment from '@/components/common/Modals/ModalAddEditComment';
import ModalViewComment from '@/components/common/Modals/ModalViewComment';

import { cloneObject } from '@/helpers';
import {
  statusStudy,
  periodoCoberturaMorte,
  periodoCoberturaVida,
  periodoCoberturaInvalidez,
  acomodacaoOptions,
  tipoCoberturaOptions,
  stateOptions,
  tipoPessoaSaudeOptions,
  formaConstrucaoOptions,
  indiceCorrecaoOptions,
  tipoBemOptions,
  dreamTypeOptions,
  dreamYearOptions
} from '@/constants/options';

import { updateDiagnosisProductItem } from '@/services/requests/diagnosis';

Vue.use(VueMask);

export default {
  name: 'StepViewItem',

  props: {
    item: Object,
    diagnosis: Object,
    diagnosisProduct: Object
  },

  components: {
    Money,
    ButtonEditComment,
    StepItemGraph,
    ContactDataTable,
    ContactDataHealthTable,
    ConsultantDataTable,
    ModalAddEditComment,
    ModalViewComment
  },

  data() {
    return {
      statusStudy,
      periodoCoberturaMorte,
      periodoCoberturaVida,
      periodoCoberturaInvalidez,
      acomodacaoOptions,
      tipoCoberturaOptions,
      stateOptions,
      tipoPessoaSaudeOptions,
      formaConstrucaoOptions,
      indiceCorrecaoOptions,
      tipoBemOptions,
      dreamTypeOptions,
      dreamYearOptions,
      editMode: false,
      currentOptionIndexTable: 0,
      currentOptionIndex: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      modalData: null,
      isOpenModalAddEditComment: false,
      isOpenModalViewComment: false
    };
  },

  methods: {
    handleChangePeriodoCobertura(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].periodo_pagamento = value;
    },

    handleChangePeriodoCoberturaSugerido(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].periodo_pagamento_sugerido = value;
    },

    handleChangeFunPeriodoCobertura(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].fun_periodo_pagamento = value;
    },

    handleChangeFunPeriodoCoberturaSugerido(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].fun_periodo_pagamento_sugerido = value;
    },

    handlePrevOption() {
      this.currentOptionIndex--;
    },

    handleNextOption() {
      this.currentOptionIndex++;
    },

    updateCustomerResponse() {
      const data = cloneObject(this.item);

      updateDiagnosisProductItem(this.item.diagnostico_produto_item_id, data)
        .then(() => {
          if (data.resposta_cliente === 2) {
            if (
              !data.diagnostico_comentario ||
              data.diagnostico_comentario.comentario
            ) {
              this.openModalAddEditComment();
              alert('Insira no comentário o ajuste que deve ser realizado');
            }
          }
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    openModalAddEditComment() {
      this.modalData = this.item.diagnostico_comentario || {
        diagnostico_comentario_id: null,
        diagnostico_produto_item_id: this.item.diagnostico_produto_item_id,
        comentario: null,
        download_url: null
      };

      this.isOpenModalAddEditComment = true;
    },

    onSaveComment(diagnosisComment) {
      this.$set(this.item, 'diagnostico_comentario', diagnosisComment);
      this.closeModalAddEditComment();
    },

    closeModalAddEditComment() {
      this.isOpenModalAddEditComment = false;
    },

    viewSpecialistComment() {
      this.isOpenModalViewComment = true;
    },

    closeModalViewComment() {
      this.isOpenModalViewComment = false;
    },

    handleChangeOpcaoSelecionada(necessario = false) {
      const data = cloneObject(this.item);
      data.opcao_selecionada_necessario = necessario;
      data.opcao_selecionada_id = necessario
        ? null
        : this.currentOption.diagnostico_produto_item_opcao_id;

      updateDiagnosisProductItem(this.item.diagnostico_produto_item_id, data)
        .then((res) => {
          const { data: item } = res.data.result;

          this.$set(
            this.item,
            'opcao_selecionada_id',
            item.opcao_selecionada_id
          );
          this.$set(
            this.item,
            'opcao_selecionada_necessario',
            item.opcao_selecionada_necessario
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleChangeOpcaoSelecionadaFuneral(necessario = false) {
      const data = cloneObject(this.item);
      data.fun_opcao_selecionada_necessario = necessario;
      data.fun_opcao_selecionada_id = this.currentOption.diagnostico_produto_item_opcao_id;

      updateDiagnosisProductItem(this.item.diagnostico_produto_item_id, data)
        .then((res) => {
          const { data: item } = res.data.result;

          this.$set(
            this.item,
            'fun_opcao_selecionada_id',
            item.fun_opcao_selecionada_id
          );
          this.$set(
            this.item,
            'fun_opcao_selecionada_necessario',
            item.fun_opcao_selecionada_necessario
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleChangeOpcaoSelecionadaSaude(dependentes) {
      const data = cloneObject(this.item);
      data.opcao_selecionada_dependentes = dependentes;
      data.opcao_selecionada_id = this.currentOption.diagnostico_produto_item_opcao_id;

      updateDiagnosisProductItem(this.item.diagnostico_produto_item_id, data)
        .then((res) => {
          const { data: item } = res.data.result;

          this.$set(
            this.item,
            'opcao_selecionada_id',
            item.opcao_selecionada_id
          );
          this.$set(
            this.item,
            'opcao_selecionada_dependentes',
            item.opcao_selecionada_dependentes
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    ...mapGetters('diagnosis', ['insurersOptions']),
    isGestaoRiscoMorte() {
      return [1, 2, 3, 17].includes(this.item.id_campo);
    },
    isInvalidez() {
      return [4, 5].includes(this.item.id_campo);
    },
    currentOption() {
      const currentOption = this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ];

      if (!currentOption) return null;

      return currentOption;
    },
    hasPrevOption() {
      return !!this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex - 1
      ];
    },
    hasNextOption() {
      return !!this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex + 1
      ];
    },
    saudeDependente() {
      return this.diagnosis.pessoa.pessoa_conjuge.saude.possui_seguro_plano
        ? this.diagnosis.pessoa.pessoa_conjuge.saude
        : null;
    },
    seguroVida() {
      return this.diagnosis.pessoa.seguro_itens.find(
        (seguro) => seguro.tipo_seguro === 'Vida Inteira'
      );
    },
    seguroFuneral() {
      return this.diagnosis.pessoa.seguro_itens.find(
        (seguro) => seguro.tipo_seguro === 'Assistência ou auxílio funeral'
      );
    },
    seguroPrincipal() {
      if (this.item.seguros.length === 0) return null;

      if (this.item.id_campo === 2) {
        return this.item.seguros.find((seguro) => seguro.nome === 'Temporário');
      }

      return this.item.seguros[0];
    },
    itemFuneral() {
      return this.item.diagnostico_produto_item_tabela_itens.find(
        (item) => item.itens === 'Auxílio Funeral'
      );
    },
    isProdutoPatrimonio() {
      return this.diagnosisProduct.produto_diagnostico_id === 3;
    },
    opcaoSaudeTitular: {
      get() {
        if (!this.currentOption) return false;

        return Boolean(
          this.item.opcao_selecionada_id ===
            this.currentOption.diagnostico_produto_item_opcao_id &&
            !this.item.opcao_selecionada_dependentes
        );
      },
      set() {}
    },
    opcaoSaudeDependentes: {
      get() {
        if (!this.currentOption) return false;

        return Boolean(
          this.item.opcao_selecionada_id ===
            this.currentOption.diagnostico_produto_item_opcao_id &&
            this.item.opcao_selecionada_dependentes
        );
      },
      set() {}
    },
    faixasIdadeVidasSaude() {
      const faixasIdadeVidasSaude = [
        {
          title: '0 a 18',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 0,
          fim: 18
        },
        {
          title: '19 a 23',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 19,
          fim: 23
        },
        {
          title: '24 a 28',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 24,
          fim: 28
        },
        {
          title: '29 a 33',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 29,
          fim: 33
        },
        {
          title: '34 a 43',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 34,
          fim: 43
        },
        {
          title: '44 a 48',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 44,
          fim: 48
        },
        {
          title: '49 a 53',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 49,
          fim: 53
        },
        {
          title: '54 a 58',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 54,
          fim: 58
        },
        {
          title: '59 ou mais',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 59
        }
      ];

      if (this.diagnosis.pessoa.saude.possui_seguro_plano) {
        const idade = Number(this.diagnosis.pessoa.pessoa_idade);

        faixasIdadeVidasSaude.forEach((faixa) => {
          if (idade >= faixa.inicio && idade <= faixa.fim) {
            faixa.quantidadeTitular++;
          }
        });
      }

      if (this.diagnosis.pessoa.pessoa_conjuge.saude.possui_seguro_plano) {
        const idade = Number(
          this.diagnosis.pessoa.pessoa_conjuge.idade_conjuge
        );

        faixasIdadeVidasSaude.forEach((faixa) => {
          if (idade >= faixa.inicio && idade <= faixa.fim) {
            faixa.quantidadeConjuge++;
          }
        });
      }

      this.diagnosis.pessoa.dependentes.forEach((dependent) => {
        if (
          dependent.saude.possui_seguro_plano &&
          dependent.saude.incluso_valor_titular
        ) {
          const idade = Number(dependent.idade_dependente);

          faixasIdadeVidasSaude.forEach((faixa) => {
            if (
              idade >= faixa.inicio &&
              (idade <= faixa.fim || faixa.fim === null)
            ) {
              faixa.quantidadeTitular++;
            }
          });
        }

        if (
          dependent.saude.possui_seguro_plano &&
          dependent.saude.incluso_valor_conjuge
        ) {
          const idade = Number(dependent.idade_dependente);

          faixasIdadeVidasSaude.forEach((faixa) => {
            if (
              idade >= faixa.inicio &&
              (idade <= faixa.fim || faixa.fim === null)
            ) {
              faixa.quantidadeConjuge++;
            }
          });
        }
      });

      return faixasIdadeVidasSaude;
    }
  }
};
</script>

<style lang="scss" scoped>
.approved {
  color: #1d954b;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
}

.refused {
  color: #ff001d;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
}
</style>
