<template>
  <div>
    <div class="accordion" role="tablist" style="margin-top: 1rem">
      <ContactDataTable
        :diagnosis="diagnosis"
        v-if="data.produto_diagnostico_id === 2"
      />

      <ConsultantDataTable
        :item="groups[0].items[0]"
        v-if="data.produto_diagnostico_id === 2 && groups[0].items[0]"
      />

      <b-card no-body class="mb-1">
        <div v-for="(group, index) in groups" :key="`group1-${index}`">
          <b-card-header header-tag="header" class="px-1 pt-1 pb-0" role="tab">
            <b-button
              :style="`
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                text-transform: uppercase;
              `"
              block
              variant="secondary"
              v-b-toggle="`accordion-item-${index}`"
            >
              {{ group.name }}
              <span class="when-opened">
                <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
              </span>
              <span class="when-closed">
                <i class="bx bx-chevron-down" style="font-size: 1.5rem" />
              </span>
            </b-button>
          </b-card-header>

          <b-collapse
            :id="`accordion-item-${index}`"
            role="tabpanel"
            :visible="data.produto_diagnostico_id !== 1"
          >
            <b-card-body class="pt-0">
              <div
                v-for="(item, itemIndex) in group.items"
                :key="`item-${item.diagnostico_produto_item_id}-1`"
              >
                <div
                  :style="`
                    height: 2.5rem;
                    margin-left: -1rem;
                    margin-right: -1rem;
                    padding: 10px;
                  `"
                  :class="{ 'mt-3': itemIndex !== 0, 'bg-secondary': true }"
                  v-if="
                    item.subgrupo &&
                    (itemIndex === 0 ||
                      group.items[itemIndex - 1].subgrupo !== item.subgrupo)
                  "
                >
                  <p style="color: var(--white)">
                    {{ item.subgrupo }}
                  </p>
                </div>

                <hr
                  :style="`padding: 1px;`"
                  class="bg-secondary"
                  v-else-if="itemIndex !== 0"
                />

                <StepItem
                  :item="item"
                  :diagnosis="diagnosis"
                  :diagnosisProduct="data"
                />
              </div>

              <hr :style="`padding: 1px;`" class="bg-secondary" />
            </b-card-body>
          </b-collapse>
        </div>

        <div
          class="mt-5"
          style="padding: 0 36px"
          v-if="data.produto_diagnostico_id === 1"
        >
          <div class="mb-4">
            <p class="font-weight-bold">Valor total dos seguros atuais</p>

            <b-table-simple bordered>
              <b-thead>
                <b-tr>
                  <b-th></b-th>
                  <b-th class="text-center">Prêmio mensal</b-th>
                </b-tr>
              </b-thead>
              <tbody>
                <tr>
                  <th>Total</th>
                  <td class="text-right">
                    R$ {{ totalPremioMensalSeguros | decimal }}
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </div>

          <div>
            <p class="font-weight-bold">Valor total das opções</p>

            <b-table-simple bordered>
              <b-thead>
                <b-tr>
                  <b-th></b-th>
                  <b-th class="text-center">Prêmio mensal</b-th>
                  <b-th class="text-center">Prêmio anual</b-th>
                </b-tr>
              </b-thead>
              <tbody>
                <tr>
                  <th>Total</th>
                  <td class="text-right">
                    R$ {{ totalPremioMensal | decimal }}
                  </td>
                  <td class="text-right">
                    R$ {{ totalPremioAnual | decimal }}
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </div>
        </div>
      </b-card>

      <div v-for="(group, index) in groups" :key="`group2-${index}`">
        <div v-if="group.name === 'Direcionamento ao especialista'">
          <b-card v-once no-body class="mb-1">
            <b-card-header
              style="border-radius: none"
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                style="
                  background: var(--secondary);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                "
                block
                v-b-toggle.accordion-2
                variant="info"
                >POSIÇÃO PATRIMONIAL
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i class="bx bx-chevron-down" style="font-size: 1.5rem" />
                </span>
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" role="tabpanel">
              <b-card-body style="background: var(--background-light-grey)">
                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Graphic1 :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    id="chart_div"
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Graphic2 :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <EmergencyReserve :diagnosis="diagnosis" />
                  </b-col>
                </b-row>

                <LiquidInvestments :diagnosis="diagnosis" />

                <InvestmentProjection :diagnosis="diagnosis" />
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-once no-body class="mb-1">
            <b-card-header
              style="border-radius: none"
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                style="
                  background: var(--secondary);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                "
                block
                v-b-toggle.accordion-3
                variant="info"
              >
                LIBERDADE FINANCEIRA
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i
                    class="bx bx-chevron-down"
                    style="font-size: 1.5rem"
                  ></i> </span
              ></b-button>
            </b-card-header>

            <b-collapse id="accordion-3" role="tabpanel">
              <b-card-body style="background: var(--background-light-grey)">
                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Pension :diagnosis="diagnosis" />
                  </b-col>
                </b-row>

                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 45%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <FinancialFreedom :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    style="
                      widht: 45%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <CurrentAssetsVsFinancialFreedom :diagnosis="diagnosis" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>

          <div
            v-for="item in group.items"
            :key="`item-${item.diagnostico_produto_item_id}-3`"
          >
            <div style="width: 100%; text-align: center" class="mt-4">
              <b-form-select
                :style="`width: 30%; border-color: ${
                  !item.resposta_cliente
                    ? 'var(--blue)'
                    : item.resposta_cliente == 1
                    ? 'var(--green-later-leaf)'
                    : [2, 4].includes(item.resposta_cliente)
                    ? 'var(--yellow-light)'
                    : item.resposta_cliente == 3 && 'var(--red-light)'
                }; color: ${
                  !item.resposta_cliente
                    ? 'var(--blue)'
                    : item.resposta_cliente == 1
                    ? 'var(--green-dark)'
                    : [2, 4].includes(item.resposta_cliente)
                    ? 'var(--crete)'
                    : item.resposta_cliente == 3 && 'var(--roof-terracotta)'
                }; background-color: ${
                  !item.resposta_cliente
                    ? 'var(--white)'
                    : item.resposta_cliente == 1
                    ? 'var(--green-light)'
                    : [2, 4].includes(item.resposta_cliente)
                    ? 'var(--yellow-light-2)'
                    : item.resposta_cliente == 3 && 'var(--pink-light)'
                }`"
                v-model="item.resposta_cliente"
                :options="statusStudy"
                @change="updateCustomerResponse(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import StepItem from './StepViewItem';
import Graphic1 from '@/components/common/Graphics/Graphic1';
import Graphic2 from '@/components/common/Graphics/Graphic2';
import EmergencyReserve from '@/components/common/Graphics/EmergencyReserve';
import Pension from '@/components/common/Graphics/Pension';
import LiquidInvestments from '@/components/common/Graphics/LiquidInvestments';
import InvestmentProjection from '@/components/common/Graphics/InvestmentProjection';
import FinancialFreedom from '@/components/common/Graphics/FinancialFreedom';
import CurrentAssetsVsFinancialFreedom from '@/components/common/Graphics/CurrentAssetsVsFinancialFreedom';
import ContactDataTable from '@/components/common/ContactDataTable';
import ConsultantDataTable from '@/components/common/ConsultantDataTable';

import { cloneObject } from '@/helpers';
import { statusStudy } from '@/constants/options';
import { updateDiagnosisProductItem } from '@/services/requests/diagnosis';

export default {
  name: 'StepView',
  components: {
    StepItem,
    Graphic1,
    Graphic2,
    EmergencyReserve,
    Pension,
    LiquidInvestments,
    InvestmentProjection,
    FinancialFreedom,
    CurrentAssetsVsFinancialFreedom,
    ContactDataTable,
    ConsultantDataTable
  },
  props: {
    data: Object,
    diagnosis: Object
  },
  data() {
    return {
      statusStudy
    };
  },
  methods: {
    openModalEditComment() {
      this.$store.state.modal.isOpenModalEditComment = !this.$store.state.modal
        .isOpenModalEditComment;
    },
    updateCustomerResponse(item) {
      const data = cloneObject(item);

      updateDiagnosisProductItem(item.diagnostico_produto_item_id, data)
        .then(() => {
          if (data.resposta_cliente === 2) {
            if (
              !data.diagnostico_comentario ||
              data.diagnostico_comentario.comentario
            ) {
              this.openModalEditComment();
              alert('Insira no comentário o ajuste que deve ser realizado');
            }
          }
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    groups() {
      const groups = {};

      this.data.diagnostico_produto_itens
        .filter((item) => item.fazer_cotacao)
        .forEach((item) => {
          if (!groups[item.grupo]) {
            groups[item.grupo] = {
              name: item.grupo,
              items: []
            };
          }

          groups[item.grupo].items.push(item);
        });

      return Object.values(groups);
    },
    totalPremioMensalSeguros() {
      const totalSeguros = this.diagnosis.pessoa.seguro_itens.reduce(
        (total, seguro) => total + seguro.valor_mensal,
        0
      );
      const totalGrupoSeguros = this.diagnosis.pessoa.grupo_seguros.reduce(
        (totalGrupo, grupoSeguro) =>
          totalGrupo +
          grupoSeguro.grupo_seguro_itens.reduce(
            (total, grupoSeguroItem) => total + grupoSeguroItem.valor_mensal,
            0
          ),
        0
      );

      return totalSeguros + totalGrupoSeguros;
    },
    totalPremioMensal() {
      return this.data.diagnostico_produto_itens.reduce((acc, item) => {
        // if (item.id_campo === 1) {
        //   const optionVida = item.opcao_selecionada_necessario
        //     ? item.diagnostico_produto_item_opcoes[0]
        //     : item.diagnostico_produto_item_opcoes.find(
        //         (opcao) =>
        //           opcao.diagnostico_produto_item_opcao_id ===
        //           item.opcao_selecionada_id
        //       );

        //   if (optionVida) {
        //     if (item.opcao_selecionada_necessario) {
        //       acc += optionVida.premio_mensal_sugerido || 0;
        //     } else {
        //       acc += optionVida.premio_mensal || 0;
        //     }
        //   }

        //   const optionFuneral = item.fun_opcao_selecionada_necessario
        //     ? item.diagnostico_produto_item_opcoes[0]
        //     : item.diagnostico_produto_item_opcoes.find(
        //         (opcao) =>
        //           opcao.diagnostico_produto_item_opcao_id ===
        //           item.fun_opcao_selecionada_id
        //       );

        //   if (optionFuneral) {
        //     if (item.fun_opcao_selecionada_necessario) {
        //       acc += optionFuneral.fun_premio_mensal_sugerido || 0;
        //     } else {
        //       acc += optionFuneral.fun_premio_mensal || 0;
        //     }
        //   }

        //   return acc;
        // }
        const option = item.opcao_selecionada_necessario
          ? item.diagnostico_produto_item_opcoes[0]
          : item.diagnostico_produto_item_opcoes.find(
              (opcao) =>
                opcao.diagnostico_produto_item_opcao_id ===
                item.opcao_selecionada_id
            );

        if (option) {
          if (item.opcao_selecionada_necessario) {
            acc += option.premio_mensal_sugerido || 0;
          } else {
            acc += option.premio_mensal || 0;
          }
        }

        return acc;
      }, 0);
    },
    totalPremioAnual() {
      return this.data.diagnostico_produto_itens.reduce((acc, item) => {
        // if (item.id_campo === 1) {
        //   const optionVida = item.opcao_selecionada_necessario
        //     ? item.diagnostico_produto_item_opcoes[0]
        //     : item.diagnostico_produto_item_opcoes.find(
        //         (opcao) =>
        //           opcao.diagnostico_produto_item_opcao_id ===
        //           item.opcao_selecionada_id
        //       );

        //   if (optionVida) {
        //     if (item.opcao_selecionada_necessario) {
        //       acc += optionVida.premio_anual_sugerido || 0;
        //     } else {
        //       acc += optionVida.premio_anual || 0;
        //     }
        //   }

        //   const optionFuneral = item.fun_opcao_selecionada_necessario
        //     ? item.diagnostico_produto_item_opcoes[0]
        //     : item.diagnostico_produto_item_opcoes.find(
        //         (opcao) =>
        //           opcao.diagnostico_produto_item_opcao_id ===
        //           item.fun_opcao_selecionada_id
        //       );

        //   if (optionFuneral) {
        //     if (item.fun_opcao_selecionada_necessario) {
        //       acc += optionFuneral.fun_premio_anual_sugerido || 0;
        //     } else {
        //       acc += optionFuneral.fun_premio_anual || 0;
        //     }
        //   }

        //   return acc;
        // }
        const option = item.opcao_selecionada_necessario
          ? item.diagnostico_produto_item_opcoes[0]
          : item.diagnostico_produto_item_opcoes.find(
              (opcao) =>
                opcao.diagnostico_produto_item_opcao_id ===
                item.opcao_selecionada_id
            );

        if (option) {
          if (item.opcao_selecionada_necessario) {
            acc += option.premio_anual_sugerido || 0;
          } else {
            acc += option.premio_anual || 0;
          }
        }

        return acc;
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.box-reserva-risco-cirurgico {
  padding: 2rem;
}
.box-title-subtitle {
  p {
    color: var(--grey);
  }
}
.box-status-quotation {
  background: var(--yellow-light-2);
  color: var(--lucky);
  border: 1px solid var(--lucky-dark);
  width: 30rem;
  padding: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}
.last-text-link {
  color: var(--blue);
  font-weight: 500;
  font-size: 14px;
}
</style>
