var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticStyle:{"padding":"1rem"}},[_c('b-col',{attrs:{"md":_vm.item.tipo_grafico === 0 || [1, 3, 5, 6].includes(_vm.item.tipo_tabela)
          ? '12'
          : '6'}},[_c('h5',{staticStyle:{"margin-top":"0.5rem"}},[_vm._v(_vm._s(_vm.item.titulo))]),(_vm.item.id_campo !== 14)?_c('p',{staticStyle:{"font-size":"12.5px","margin-top":"1rem"}},[_vm._v(" "+_vm._s(_vm.item.descricao)+" ")]):_vm._e(),(
          _vm.item.tipo_tabela === 1 &&
          (_vm.item.resposta_cliente === 1 || _vm.item.resposta_cliente === 3)
        )?_c('div',{class:_vm.item.resposta_cliente === 1
            ? 'approved'
            : _vm.item.resposta_cliente === 3
            ? 'refused'
            : ''},[(_vm.item.resposta_cliente === 1)?_c('span',[_vm._v(" OPÇÃO "+_vm._s(_vm.item.opcao_selecionada_necessario ? 'VALOR SUGERIDO' : _vm.item.diagnostico_produto_item_opcoes.findIndex( function (option) { return option.diagnostico_produto_item_opcao_id === _vm.item.opcao_selecionada_id; } ) + 1)+" - APROVADA ")]):_vm._e(),(_vm.item.resposta_cliente === 3)?_c('span',[_vm._v(" PROPOSTAS RECUSADAS ")]):_vm._e()]):_vm._e(),(
          ([5, 6].includes(_vm.diagnosisProduct.produto_diagnostico_id) ||
            ['Seguro patrimonial'].includes(_vm.item.grupo) ||
            (_vm.item.id_campo === 14 &&
              _vm.diagnosisProduct.produto_diagnostico_id !== 3)) &&
          _vm.item.grupo !== 'Direcionamento ao especialista'
        )?_c('ContactDataTable',{attrs:{"diagnosis":_vm.diagnosis}}):_vm._e(),(_vm.item.grupo === 'Saúde')?_c('ContactDataHealthTable',{attrs:{"diagnosis":_vm.diagnosis}}):_vm._e(),(
          [5, 6].includes(_vm.diagnosisProduct.produto_diagnostico_id) &&
          _vm.item.grupo !== 'Direcionamento ao especialista'
        )?_c('ConsultantDataTable',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.tipo_grafico !== 0)?_c('div',{staticStyle:{"overflow-x":"auto"}},[(_vm.item.utiliza_opcoes)?_c('strong',{staticStyle:{"color":"var(--primary)"}},[_vm._v(" Preencha os campos abaixo com os dados e valores do orçamento: ")]):_vm._e(),(_vm.item.aguardando_resposta)?_c('strong',{staticStyle:{"color":"var(--primary)"}},[_vm._v(" SELECIONE ABAIXO A OPÇÃO QUE O CLIENTE APROVOU: ")]):_vm._e(),(false)?_c('span',{staticStyle:{"background":"var(--red-light)","position":"absolute","left":"1rem","top":"10rem","width":"13rem","padding":"0.5rem","border-radius":"5px","color":"var(--white)"}},[_vm._v(" Por favor, selecione uma opção para continuar. ")]):_vm._e(),(_vm.item.exibir_tabela && _vm.item.tipo_tabela === 1)?_c('table',{staticClass:"table",staticStyle:{"margin-top":"2rem"}},[_c('tr',[_c('th',[_vm._v("ITENS")]),_c('th',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                'var(--primary)') + ";")),attrs:{"colspan":"2"}},[_vm._v(" OPÇÃO "+_vm._s(_vm.currentOptionIndex + 1)+" ")]):_vm._e()]),_c('tr',[_c('th'),_c('th',{staticClass:"text-center",staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" VALOR NECESSÁRIO ")]),_c('th',{staticClass:"text-center",staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" VALOR ATUAL ")]),(_vm.currentOption)?_c('th',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario &&
                'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-button',{staticClass:"float-left",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasPrevOption},on:{"click":function($event){return _vm.handlePrevOption()}}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('b-form-radio',{staticStyle:{"margin-right":"1rem"},attrs:{"name":("item-" + (_vm.item.diagnostico_produto_item_id) + "-opcao-selecionada1"),"value":true},on:{"change":function () { return _vm.handleChangeOpcaoSelecionada(true); }},model:{value:(_vm.item.opcao_selecionada_necessario),callback:function ($$v) {_vm.$set(_vm.item, "opcao_selecionada_necessario", $$v)},expression:"item.opcao_selecionada_necessario"}},[_vm._v(" VALOR SUGERIDO ")]),_c('div')],1)]):_vm._e(),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario &&
                'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div'),_c('b-form-radio',{staticStyle:{"margin-right":"1rem"},attrs:{"name":("item-" + (_vm.item.diagnostico_produto_item_id) + "-opcao-selecionada2"),"value":_vm.currentOption.diagnostico_produto_item_opcao_id},on:{"change":function () { return _vm.handleChangeOpcaoSelecionada(); }},model:{value:(_vm.item.opcao_selecionada_id),callback:function ($$v) {_vm.$set(_vm.item, "opcao_selecionada_id", $$v)},expression:"item.opcao_selecionada_id"}},[_vm._v(" VALOR AJUSTADO ")]),_c('b-button',{staticClass:"float-right",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasNextOption},on:{"click":function($event){return _vm.handleNextOption()}}},[_c('i',{staticClass:"fas fa-arrow-right"})])],1)]):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v(" 1. Capital Segurado ")])]),_c('td',{style:("background: #e8e8ea9f;")},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.necessidades))+" ")]),_c('td',{style:("background: #e8e8ea9f;")},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.valor_atual))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.capital_segurado_sugerido))+" ")]):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.capital_segurado),callback:function ($$v) {_vm.$set(_vm.currentOption, "capital_segurado", $$v)},expression:"currentOption.capital_segurado"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.capital_segurado))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("2. Seguradora Sugerida")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroPrincipal)?_c('span',[_vm._v(" "+_vm._s(_vm.seguroPrincipal.seguradora)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.insurersOptions},model:{value:(_vm.currentOption.seguradora_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "seguradora_sugerido", $$v)},expression:"currentOption.seguradora_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.seguradora_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.insurersOptions},model:{value:(_vm.currentOption.seguradora),callback:function ($$v) {_vm.$set(_vm.currentOption, "seguradora", $$v)},expression:"currentOption.seguradora"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.seguradora))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("3. Nome da solução")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroPrincipal)?_c('span',[_vm._v(" "+_vm._s(_vm.seguroPrincipal.tipo_seguro)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.solucao_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "solucao_sugerido", $$v)},expression:"currentOption.solucao_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.solucao_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.solucao),callback:function ($$v) {_vm.$set(_vm.currentOption, "solucao", $$v)},expression:"currentOption.solucao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.solucao))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("4. Período de cobertura")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")}),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.isGestaoRiscoMorte
                    ? _vm.periodoCoberturaMorte
                    : _vm.isInvalidez
                    ? _vm.periodoCoberturaInvalidez
                    : _vm.periodoCoberturaVida},on:{"change":function (value) { return _vm.handleChangePeriodoCoberturaSugerido(value); }},model:{value:(_vm.currentOption.periodo_cobertura_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_cobertura_sugerido", $$v)},expression:"currentOption.periodo_cobertura_sugerido"}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("periodoCobertura")(_vm.currentOption.periodo_cobertura_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.isGestaoRiscoMorte
                    ? _vm.periodoCoberturaMorte
                    : _vm.periodoCoberturaVida},on:{"change":function (value) { return _vm.handleChangePeriodoCobertura(value); }},model:{value:(_vm.currentOption.periodo_cobertura),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_cobertura", $$v)},expression:"currentOption.periodo_cobertura"}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("periodoCobertura")(_vm.currentOption.periodo_cobertura))+" ")])],1):_vm._e()]),(_vm.isGestaoRiscoMorte)?_c('tr',[_c('td',[_c('span',[_vm._v("5. Período de pagamento")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")}),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaMorte},model:{value:(_vm.currentOption.periodo_pagamento_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_pagamento_sugerido", $$v)},expression:"currentOption.periodo_pagamento_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.periodo_pagamento_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaMorte},model:{value:(_vm.currentOption.periodo_pagamento),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_pagamento", $$v)},expression:"currentOption.periodo_pagamento"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.periodo_pagamento))])],1):_vm._e()]):_vm._e(),_c('tr',[_c('td',[_c('span',[_vm._v("6. Prêmio mensal")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroPrincipal)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.seguroPrincipal.valor_mensal))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_mensal_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_mensal_sugerido", $$v)},expression:"currentOption.premio_mensal_sugerido"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_mensal_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_mensal", $$v)},expression:"currentOption.premio_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_mensal))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("7. Prêmio anual")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroPrincipal)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")((_vm.seguroPrincipal.valor_mensal * 12)))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_anual_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_anual_sugerido", $$v)},expression:"currentOption.premio_anual_sugerido"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_anual_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_necessario
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_anual),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_anual", $$v)},expression:"currentOption.premio_anual"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_anual))+" ")])],1):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original))]),_c('a',{attrs:{"href":_vm.currentOption.download_url,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e(),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url_2)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original_2))]),_c('a',{attrs:{"href":_vm.currentOption.download_url_2,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e()])]):_vm._e(),(
            _vm.item.exibir_tabela &&
            _vm.item.tipo_tabela === 1 &&
            _vm.item.id_campo === 1 &&
            false
          )?_c('div',[_c('table',{staticClass:"table",staticStyle:{"margin-top":"2rem"}},[_c('tr',[_c('th',[_vm._v("ITENS")]),_c('th',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  'var(--primary)') + ";")),attrs:{"colspan":"2"}},[_vm._v(" OPÇÃO "+_vm._s(_vm.currentOptionIndex + 1)+" ")]):_vm._e()]),_c('tr',[_c('th',[_vm._v("VIDA")]),_c('th',{staticClass:"text-center",staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" VALOR NECESSÁRIO ")]),_c('th',{staticClass:"text-center",staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" VALOR ATUAL ")]),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario &&
                  'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-button',{staticClass:"float-left",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasPrevOption},on:{"click":function($event){return _vm.handlePrevOption()}}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('b-form-radio',{staticStyle:{"margin-right":"1rem"},attrs:{"name":("item-" + (_vm.item.diagnostico_produto_item_id) + "-opcao-selecionada3"),"value":true},on:{"change":function () { return _vm.handleChangeOpcaoSelecionada(true); }},model:{value:(_vm.item.opcao_selecionada_necessario),callback:function ($$v) {_vm.$set(_vm.item, "opcao_selecionada_necessario", $$v)},expression:"item.opcao_selecionada_necessario"}},[_vm._v(" VALOR SUGERIDO ")]),_c('div')],1)]):_vm._e(),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario &&
                  'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div'),_c('b-form-radio',{staticStyle:{"margin-right":"1rem"},attrs:{"name":("item-" + (_vm.item.diagnostico_produto_item_id) + "-opcao-selecionada4"),"value":_vm.currentOption.diagnostico_produto_item_opcao_id},on:{"change":function () { return _vm.handleChangeOpcaoSelecionada(); }},model:{value:(_vm.item.opcao_selecionada_id),callback:function ($$v) {_vm.$set(_vm.item, "opcao_selecionada_id", $$v)},expression:"item.opcao_selecionada_id"}},[_vm._v(" VALOR AJUSTADO ")]),_c('b-button',{staticClass:"float-right",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasNextOption},on:{"click":function($event){return _vm.handleNextOption()}}},[_c('i',{staticClass:"fas fa-arrow-right"})])],1)]):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v(" 1. Capital Segurado ")])]),_c('td',{style:("background: #e8e8ea9f;")},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.necessidades))+" ")]),_c('td',{style:("background: #e8e8ea9f;")},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.valor_atual))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.capital_segurado_sugerido))+" ")]):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.capital_segurado),callback:function ($$v) {_vm.$set(_vm.currentOption, "capital_segurado", $$v)},expression:"currentOption.capital_segurado"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.capital_segurado))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("2. Seguradora Sugerida")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroVida)?_c('span',[_vm._v(" "+_vm._s(_vm.seguroVida.seguradora)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.insurersOptions},model:{value:(_vm.currentOption.seguradora_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "seguradora_sugerido", $$v)},expression:"currentOption.seguradora_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.seguradora_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.insurersOptions},model:{value:(_vm.currentOption.seguradora),callback:function ($$v) {_vm.$set(_vm.currentOption, "seguradora", $$v)},expression:"currentOption.seguradora"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.seguradora))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("3. Nome da solução")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroVida)?_c('span',[_vm._v(" "+_vm._s(_vm.seguroVida.tipo_seguro)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.solucao_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "solucao_sugerido", $$v)},expression:"currentOption.solucao_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.solucao_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.solucao),callback:function ($$v) {_vm.$set(_vm.currentOption, "solucao", $$v)},expression:"currentOption.solucao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.solucao))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("4. Período de cobertura")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")}),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.isGestaoRiscoMorte
                      ? _vm.periodoCoberturaMorte
                      : _vm.periodoCoberturaVida},on:{"change":function (value) { return _vm.handleChangePeriodoCoberturaSugerido(value); }},model:{value:(_vm.currentOption.periodo_cobertura_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_cobertura_sugerido", $$v)},expression:"currentOption.periodo_cobertura_sugerido"}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("periodoCobertura")(_vm.currentOption.periodo_cobertura_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.isGestaoRiscoMorte
                      ? _vm.periodoCoberturaMorte
                      : _vm.periodoCoberturaVida},on:{"change":function (value) { return _vm.handleChangePeriodoCobertura(value); }},model:{value:(_vm.currentOption.periodo_cobertura),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_cobertura", $$v)},expression:"currentOption.periodo_cobertura"}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("periodoCobertura")(_vm.currentOption.periodo_cobertura))+" ")])],1):_vm._e()]),(_vm.isGestaoRiscoMorte)?_c('tr',[_c('td',[_c('span',[_vm._v("5. Período de pagamento")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")}),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaMorte},model:{value:(_vm.currentOption.periodo_pagamento_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_pagamento_sugerido", $$v)},expression:"currentOption.periodo_pagamento_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.periodo_pagamento_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaMorte},model:{value:(_vm.currentOption.periodo_pagamento),callback:function ($$v) {_vm.$set(_vm.currentOption, "periodo_pagamento", $$v)},expression:"currentOption.periodo_pagamento"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.periodo_pagamento))])],1):_vm._e()]):_vm._e(),_c('tr',[_c('td',[_c('span',[_vm._v("6. Prêmio mensal")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroVida)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.seguroVida.valor_mensal))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_mensal_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_mensal_sugerido", $$v)},expression:"currentOption.premio_mensal_sugerido"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_mensal_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_mensal", $$v)},expression:"currentOption.premio_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_mensal))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("7. Prêmio anual")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroVida)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")((_vm.seguroVida.valor_mensal * 12)))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  _vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_anual_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_anual_sugerido", $$v)},expression:"currentOption.premio_anual_sugerido"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_anual_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.opcao_selecionada_id &&
                  !_vm.item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.premio_anual),callback:function ($$v) {_vm.$set(_vm.currentOption, "premio_anual", $$v)},expression:"currentOption.premio_anual"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.premio_anual))+" ")])],1):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original))]),_c('a',{attrs:{"href":_vm.currentOption.download_url,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e(),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url_2)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original_2))]),_c('a',{attrs:{"href":_vm.currentOption.download_url_2,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e()])]),_c('table',{staticClass:"table",staticStyle:{"margin-top":"2rem"}},[_c('tr',[_c('th',[_vm._v("ITENS")]),_c('th',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('th',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  'var(--primary)') + ";")),attrs:{"colspan":"2"}},[_vm._v(" OPÇÃO "+_vm._s(_vm.currentOptionIndex + 1)+" ")]):_vm._e()]),_c('tr',[_c('th',[_vm._v("FUNERAL")]),_c('th',{staticClass:"text-center",staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" VALOR NECESSÁRIO ")]),_c('th',{staticClass:"text-center",staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" VALOR ATUAL ")]),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario &&
                  'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-button',{staticClass:"float-left",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasPrevOption},on:{"click":function($event){return _vm.handlePrevOption()}}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_c('b-form-radio',{staticStyle:{"margin-right":"1rem"},attrs:{"name":("item-" + (_vm.item.diagnostico_produto_item_id) + "-opcao-selecionada3"),"value":true},on:{"change":function () { return _vm.handleChangeOpcaoSelecionadaFuneral(true); }},model:{value:(_vm.item.fun_opcao_selecionada_necessario),callback:function ($$v) {_vm.$set(_vm.item, "fun_opcao_selecionada_necessario", $$v)},expression:"item.fun_opcao_selecionada_necessario"}},[_vm._v(" VALOR SUGERIDO ")]),_c('div')],1)]):_vm._e(),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario &&
                  'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div'),_c('b-form-radio',{staticStyle:{"margin-right":"1rem"},attrs:{"name":("item-" + (_vm.item.diagnostico_produto_item_id) + "-opcao-selecionada4"),"value":_vm.currentOption.diagnostico_produto_item_opcao_id},on:{"change":function () { return _vm.handleChangeOpcaoSelecionadaFuneral(); }},model:{value:(_vm.item.fun_opcao_selecionada_id),callback:function ($$v) {_vm.$set(_vm.item, "fun_opcao_selecionada_id", $$v)},expression:"item.fun_opcao_selecionada_id"}},[_vm._v(" VALOR AJUSTADO ")]),_c('b-button',{staticClass:"float-right",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasNextOption},on:{"click":function($event){return _vm.handleNextOption()}}},[_c('i',{staticClass:"fas fa-arrow-right"})])],1)]):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v(" 1. Capital Segurado ")])]),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.itemFuneral.necessidades)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.itemFuneral.necessidades))+" ")]):_vm._e()]),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.itemFuneral.valor_atual)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.itemFuneral.valor_atual))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.fun_capital_segurado_sugerido))+" ")]):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.fun_capital_segurado),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_capital_segurado", $$v)},expression:"currentOption.fun_capital_segurado"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.fun_capital_segurado))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("2. Seguradora Sugerida")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroFuneral)?_c('span',[_vm._v(" "+_vm._s(_vm.seguroFuneral.seguradora)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.insurersOptions},model:{value:(_vm.currentOption.fun_seguradora_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_seguradora_sugerido", $$v)},expression:"currentOption.fun_seguradora_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.fun_seguradora_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.insurersOptions},model:{value:(_vm.currentOption.fun_seguradora),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_seguradora", $$v)},expression:"currentOption.fun_seguradora"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.fun_seguradora))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("3. Nome da solução")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroFuneral)?_c('span',[_vm._v(" "+_vm._s(_vm.seguroFuneral.tipo_seguro)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.fun_solucao_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_solucao_sugerido", $$v)},expression:"currentOption.fun_solucao_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.fun_solucao_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.fun_solucao),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_solucao", $$v)},expression:"currentOption.fun_solucao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.fun_solucao))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("4. Período de cobertura")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")}),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaVida},on:{"change":function (value) { return _vm.handleChangeFunPeriodoCoberturaSugerido(value); }},model:{value:(_vm.currentOption.fun_periodo_cobertura_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_periodo_cobertura_sugerido", $$v)},expression:"currentOption.fun_periodo_cobertura_sugerido"}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("periodoCobertura")(_vm.currentOption.fun_periodo_cobertura_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaVida},on:{"change":function (value) { return _vm.handleChangeFunPeriodoCobertura(value); }},model:{value:(_vm.currentOption.fun_periodo_cobertura),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_periodo_cobertura", $$v)},expression:"currentOption.fun_periodo_cobertura"}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("periodoCobertura")(_vm.currentOption.fun_periodo_cobertura))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("5. Período de pagamento")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")}),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaVida},model:{value:(_vm.currentOption.fun_periodo_pagamento_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_periodo_pagamento_sugerido", $$v)},expression:"currentOption.fun_periodo_pagamento_sugerido"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.fun_periodo_pagamento_sugerido))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.periodoCoberturaVida},model:{value:(_vm.currentOption.fun_periodo_pagamento),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_periodo_pagamento", $$v)},expression:"currentOption.fun_periodo_pagamento"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.fun_periodo_pagamento))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("6. Prêmio mensal")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroFuneral)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.seguroFuneral.valor_mensal))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.fun_premio_mensal_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_premio_mensal_sugerido", $$v)},expression:"currentOption.fun_premio_mensal_sugerido"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.fun_premio_mensal_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.fun_premio_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_premio_mensal", $$v)},expression:"currentOption.fun_premio_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.fun_premio_mensal))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("7. Prêmio anual")])]),_c('td',{style:("background: #e8e8ea9f;")}),_c('td',{style:("background: #e8e8ea9f;")},[(_vm.seguroFuneral)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")((_vm.seguroFuneral.valor_mensal * 12)))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  _vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.fun_premio_anual_sugerido),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_premio_anual_sugerido", $$v)},expression:"currentOption.fun_premio_anual_sugerido"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.fun_premio_anual_sugerido))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id &&
                  _vm.currentOption.diagnostico_produto_item_opcao_id ===
                    _vm.item.fun_opcao_selecionada_id &&
                  !_vm.item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.fun_premio_anual),callback:function ($$v) {_vm.$set(_vm.currentOption, "fun_premio_anual", $$v)},expression:"currentOption.fun_premio_anual"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.fun_premio_anual))+" ")])],1):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original))]),_c('a',{attrs:{"href":_vm.currentOption.download_url,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e(),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url_2)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original_2))]),_c('a',{attrs:{"href":_vm.currentOption.download_url_2,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e()])])]):_vm._e(),(_vm.item.tipo_tabela === 3)?_c('table',{staticClass:"table",staticStyle:{"margin-top":"2rem"}},[_c('tr',[_c('th'),_c('th',{style:("background: #e8e8ea9f;"),attrs:{"colspan":"2"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_vm._v(" PLANO ATUAL ")])]),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                'var(--primary)') + ";")),attrs:{"colspan":"2"}},[_vm._v(" OPÇÃO "+_vm._s(_vm.currentOptionIndex + 1)+" ")]):_vm._e()]),_c('tr',[_c('th'),_c('th',{staticClass:"text-center",style:("background: #e8e8ea9f;")},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[(_vm.currentOption)?_c('b-button',{staticClass:"float-left",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasPrevOption},on:{"click":function($event){return _vm.handlePrevOption()}}},[_c('i',{staticClass:"fas fa-arrow-left"})]):_vm._e(),_vm._v(" TITULAR "),_c('div')],1)]),_c('th',{staticClass:"text-center",style:("background: #e8e8ea9f;")},[_vm._v(" DEPENDENTES NÃO INCLUSOS ")]),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes &&
                'var(--primary)') + ";"))},[_c('b-form-radio',{attrs:{"value":true},on:{"change":function () { return _vm.handleChangeOpcaoSelecionadaSaude(false); }},model:{value:(_vm.opcaoSaudeTitular),callback:function ($$v) {_vm.opcaoSaudeTitular=$$v},expression:"opcaoSaudeTitular"}},[_vm._v(" TITULAR ")])],1):_vm._e(),(_vm.currentOption)?_c('th',{staticClass:"text-center",style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes &&
                'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div'),_c('b-form-radio',{attrs:{"value":true},on:{"change":function () { return _vm.handleChangeOpcaoSelecionadaSaude(true); }},model:{value:(_vm.opcaoSaudeDependentes),callback:function ($$v) {_vm.opcaoSaudeDependentes=$$v},expression:"opcaoSaudeDependentes"}},[_vm._v(" DEPENDENTES NÃO INCLUSOS ")]),_c('b-button',{staticClass:"float-right",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasNextOption},on:{"click":function($event){return _vm.handleNextOption()}}},[_c('i',{staticClass:"fas fa-arrow-right"})])],1)]):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("1. Pessoa física ou jurídica")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.tipo_pessoa)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.tipo_pessoa)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.tipoPessoaSaudeOptions},model:{value:(_vm.currentOption.tipo_pessoa),callback:function ($$v) {_vm.$set(_vm.currentOption, "tipo_pessoa", $$v)},expression:"currentOption.tipo_pessoa"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.tipo_pessoa))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.tipoPessoaSaudeOptions},model:{value:(_vm.currentOption.dep_tipo_pessoa),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_tipo_pessoa", $$v)},expression:"currentOption.dep_tipo_pessoa"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_tipo_pessoa))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("2. Operadora")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.operadora)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.operadora)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.operadora),callback:function ($$v) {_vm.$set(_vm.currentOption, "operadora", $$v)},expression:"currentOption.operadora"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.operadora))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.dep_operadora),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_operadora", $$v)},expression:"currentOption.dep_operadora"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_operadora))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("3. Estado")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.estado)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.estado)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.stateOptions},model:{value:(_vm.currentOption.estado),callback:function ($$v) {_vm.$set(_vm.currentOption, "estado", $$v)},expression:"currentOption.estado"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.estado))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.stateOptions},model:{value:(_vm.currentOption.dep_estado),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_estado", $$v)},expression:"currentOption.dep_estado"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_estado))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("4. Cidade")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.cidade)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.cidade)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.cidade),callback:function ($$v) {_vm.$set(_vm.currentOption, "cidade", $$v)},expression:"currentOption.cidade"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.cidade))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.dep_cidade),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_cidade", $$v)},expression:"currentOption.dep_cidade"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_cidade))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("5. Nome de produto")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.nome_produto)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.nome_produto)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.nome_produto),callback:function ($$v) {_vm.$set(_vm.currentOption, "nome_produto", $$v)},expression:"currentOption.nome_produto"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.nome_produto))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.dep_nome_produto),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_nome_produto", $$v)},expression:"currentOption.dep_nome_produto"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_nome_produto))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("6. Tipo de cobertura")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.tipo_cobertura)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.tipo_cobertura)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.tipoCoberturaOptions},model:{value:(_vm.currentOption.tipo_cobertura),callback:function ($$v) {_vm.$set(_vm.currentOption, "tipo_cobertura", $$v)},expression:"currentOption.tipo_cobertura"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.tipo_cobertura))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.tipoCoberturaOptions},model:{value:(_vm.currentOption.dep_tipo_cobertura),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_tipo_cobertura", $$v)},expression:"currentOption.dep_tipo_cobertura"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_tipo_cobertura))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("7. Acomodação")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.acomodacao)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.acomodacao)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.acomodacaoOptions},model:{value:(_vm.currentOption.acomodacao),callback:function ($$v) {_vm.$set(_vm.currentOption, "acomodacao", $$v)},expression:"currentOption.acomodacao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.acomodacao))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.acomodacaoOptions},model:{value:(_vm.currentOption.dep_acomodacao),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_acomodacao", $$v)},expression:"currentOption.dep_acomodacao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_acomodacao))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("8. Hospital de preferência")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.possui_preferencia)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.possui_preferencia)+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.possui_preferencia),callback:function ($$v) {_vm.$set(_vm.currentOption, "possui_preferencia", $$v)},expression:"currentOption.possui_preferencia"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.possui_preferencia))])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.dep_possui_preferencia),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_possui_preferencia", $$v)},expression:"currentOption.dep_possui_preferencia"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.dep_possui_preferencia))])],1):_vm._e()]),_c('tr',[_c('td',[_vm._v("9. Vidas")]),_c('td',{staticStyle:{"background-color":"#e8e8ea9f"},attrs:{"colspan":"4"}})]),_vm._l((_vm.faixasIdadeVidasSaude),function(faixa,index){return _c('tr',{key:index},[_c('td',{staticClass:"pl-4"},[_vm._v(_vm._s(faixa.title))]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(faixa.quantidadeTitular || '-')+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(faixa.quantidadeConjuge || '-')+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" "+_vm._s(faixa.quantidadeTitular || '-')+" ")]):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" "+_vm._s(faixa.quantidadeConjuge || '-')+" ")]):_vm._e()])}),_c('tr',[_c('td',[_c('span',[_vm._v("10. Valor mensal")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.diagnosis.pessoa.saude.valor_mensal))+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.saudeDependente.valor_mensal))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_mensal", $$v)},expression:"currentOption.valor_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_mensal))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.dep_valor_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_valor_mensal", $$v)},expression:"currentOption.dep_valor_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.dep_valor_mensal))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("11. Valor de reembolso")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.diagnosis.pessoa.saude.valor_reembolso))+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.saudeDependente.valor_reembolso))+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_reembolso),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_reembolso", $$v)},expression:"currentOption.valor_reembolso"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_reembolso))+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.dep_valor_reembolso),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_valor_reembolso", $$v)},expression:"currentOption.dep_valor_reembolso"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.dep_valor_reembolso))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("12. Co-participação")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.diagnosis.pessoa.saude.coparticipacao ? 'Sim' : 'Não')+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[(_vm.saudeDependente)?_c('span',[_vm._v(" "+_vm._s(_vm.saudeDependente.coparticipacao ? 'Sim' : 'Não')+" ")]):_vm._e()]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(_vm.currentOption.coparticipacao),callback:function ($$v) {_vm.$set(_vm.currentOption, "coparticipacao", $$v)},expression:"currentOption.coparticipacao"}}):_c('span',[_vm._v(" "+_vm._s(_vm.currentOption.coparticipacao ? 'Sim' : 'Não')+" ")])],1):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(_vm.currentOption.dep_coparticipacao),callback:function ($$v) {_vm.$set(_vm.currentOption, "dep_coparticipacao", $$v)},expression:"currentOption.dep_coparticipacao"}}):_c('span',[_vm._v(" "+_vm._s(_vm.currentOption.dep_coparticipacao ? 'Sim' : 'Não')+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("13. Validade da proposta")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[9].coluna_valor)+" ")]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[9].coluna_valor)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                !_vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" "+_vm._s(_vm.currentOption.validade_proposta)+" ")]):_vm._e(),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                _vm.item.opcao_selecionada_dependentes
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" "+_vm._s(_vm.currentOption.dep_validade_proposta)+" ")]):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticStyle:{"background":"#e8e8ea9f"},attrs:{"colspan":"2"}}),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original))]),_c('a',{attrs:{"href":_vm.currentOption.download_url,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e(),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url_2)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original_2))]),_c('a',{attrs:{"href":_vm.currentOption.download_url_2,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e()])],2):_vm._e(),(_vm.item.tipo_tabela === 5)?_c('table',{staticClass:"table",staticStyle:{"margin-top":"2rem"}},[_c('tr',[_c('th'),_c('th',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                'var(--primary)') + ";"))},[(_vm.currentOption)?_c('b-button',{staticClass:"float-left",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasPrevOption},on:{"click":function($event){return _vm.handlePrevOption()}}},[_c('i',{staticClass:"fas fa-arrow-left"})]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_vm._v(" ATUAL ")])],1),(_vm.currentOption)?_c('th',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div'),_c('span',{staticStyle:{"margin-right":"1rem"}},[_vm._v(" OPÇÃO "+_vm._s(_vm.currentOptionIndex + 1)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticClass:"float-right ml-3",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasNextOption},on:{"click":function($event){return _vm.handleNextOption()}}},[_c('i',{staticClass:"fas fa-arrow-right"})])],1)])]):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("1. Nome do bem")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[0].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.nome_bem),callback:function ($$v) {_vm.$set(_vm.currentOption, "nome_bem", $$v)},expression:"currentOption.nome_bem"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.nome_bem))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("2. Tipo")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[1].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.tipoBemOptions},model:{value:(_vm.currentOption.tipo),callback:function ($$v) {_vm.$set(_vm.currentOption, "tipo", $$v)},expression:"currentOption.tipo"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.tipo))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("3. Valor do bem")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.diagnostico_produto_item_tabela_itens[2].valor_cliente))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_bem),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_bem", $$v)},expression:"currentOption.valor_bem"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_bem))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("4. Valor financiado")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.diagnostico_produto_item_tabela_itens[3].valor_cliente))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_financiado),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_financiado", $$v)},expression:"currentOption.valor_financiado"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_financiado))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("5. Parcela mensal")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.diagnostico_produto_item_tabela_itens[4].valor_cliente))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_mensal", $$v)},expression:"currentOption.valor_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_mensal))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("6. Quantas parcelas faltam")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[5].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"type":"text"},model:{value:(_vm.currentOption.parcelas_restantes),callback:function ($$v) {_vm.$set(_vm.currentOption, "parcelas_restantes", $$v)},expression:"currentOption.parcelas_restantes"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.parcelas_restantes))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("7. Forma de construção")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[6].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.formaConstrucaoOptions},model:{value:(_vm.currentOption.forma_construcao),callback:function ($$v) {_vm.$set(_vm.currentOption, "forma_construcao", $$v)},expression:"currentOption.forma_construcao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.forma_construcao))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("8. Taxa de correção")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[7].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.indiceCorrecaoOptions},model:{value:(_vm.currentOption.taxa_correcao),callback:function ($$v) {_vm.$set(_vm.currentOption, "taxa_correcao", $$v)},expression:"currentOption.taxa_correcao"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.taxa_correcao))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("9. Operadora")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[8].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.operadora),callback:function ($$v) {_vm.$set(_vm.currentOption, "operadora", $$v)},expression:"currentOption.operadora"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.operadora))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("10. Valor de entrada ou lance")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[9].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.isProdutoPatrimonio && !_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_entrada),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_entrada", $$v)},expression:"currentOption.valor_entrada"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(_vm._s(_vm.currentOption.valor_entrada))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("11. Validade da proposta")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[10].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" "+_vm._s(_vm.currentOption.validade_proposta)+" ")]):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticStyle:{"background":"#e8e8ea9f"}}),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original))]),_c('a',{attrs:{"href":_vm.currentOption.download_url,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.item.tipo_tabela === 6)?_c('table',{staticClass:"table",staticStyle:{"margin-top":"2rem"}},[_c('tr',[_c('th'),_c('th',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                'var(--primary)') + ";"))},[(_vm.currentOption)?_c('b-button',{staticClass:"float-left",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasPrevOption},on:{"click":function($event){return _vm.handlePrevOption()}}},[_c('i',{staticClass:"fas fa-arrow-left"})]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_vm._v(" ATUAL ")])],1),(_vm.currentOption)?_c('th',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id &&
                'var(--primary)') + ";"))},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div'),_c('span',{staticStyle:{"margin-right":"1rem"}},[_vm._v(" OPÇÃO "+_vm._s(_vm.currentOptionIndex + 1)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticClass:"float-right ml-3",staticStyle:{"background-color":"#eff2f7"},attrs:{"size":"sm","variant":"light","disabled":!_vm.hasNextOption},on:{"click":function($event){return _vm.handleNextOption()}}},[_c('i',{staticClass:"fas fa-arrow-right"})])],1)])]):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("1. Nome do bem")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[0].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.currentOption.nome_bem),callback:function ($$v) {_vm.$set(_vm.currentOption, "nome_bem", $$v)},expression:"currentOption.nome_bem"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.nome_bem))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("2. Tipo")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[1].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.dreamTypeOptions},model:{value:(_vm.currentOption.tipo),callback:function ($$v) {_vm.$set(_vm.currentOption, "tipo", $$v)},expression:"currentOption.tipo"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.tipo))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("3. Valor do bem")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.diagnostico_produto_item_tabela_itens[2].valor_cliente))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_bem),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_bem", $$v)},expression:"currentOption.valor_bem"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_bem))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("4. Valor de entrada")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.diagnostico_produto_item_tabela_itens[3].valor_cliente))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_entrada),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_entrada", $$v)},expression:"currentOption.valor_entrada"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_entrada))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("5. Parcela mensal")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.item.diagnostico_produto_item_tabela_itens[4].valor_cliente))+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.currentOption.valor_mensal),callback:function ($$v) {_vm.$set(_vm.currentOption, "valor_mensal", $$v)},expression:"currentOption.valor_mensal"}},'money',_vm.moneyMask,false)):_c('span',[_vm._v(" R$ "+_vm._s(_vm._f("decimal")(_vm.currentOption.valor_mensal))+" ")])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("6. Para quando")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[5].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[(!_vm.item.aguardando_resposta)?_c('b-form-select',{attrs:{"options":_vm.dreamYearOptions},model:{value:(_vm.currentOption.para_quando),callback:function ($$v) {_vm.$set(_vm.currentOption, "para_quando", $$v)},expression:"currentOption.para_quando"}}):_c('span',[_vm._v(_vm._s(_vm.currentOption.para_quando))])],1):_vm._e()]),_c('tr',[_c('td',[_c('span',[_vm._v("7. Validade da proposta")])]),_c('td',{staticStyle:{"background":"#e8e8ea9f","color":"#495057"}},[_vm._v(" "+_vm._s(_vm.item.diagnostico_produto_item_tabela_itens[6].valor_cliente)+" ")]),(_vm.currentOption)?_c('td',{style:(("background: #e8e8ea9f; color: " + (_vm.currentOption &&
                _vm.currentOption.diagnostico_produto_item_opcao_id ===
                  _vm.item.opcao_selecionada_id
                  ? '#1D954B'
                  : '#495057') + ";"))},[_vm._v(" "+_vm._s(_vm.currentOption.validade_proposta)+" ")]):_vm._e()]),_c('tr',[_c('td'),_c('td',{staticStyle:{"background":"#e8e8ea9f"}}),(_vm.currentOption)?_c('td',{staticStyle:{"background":"#e8e8ea9f"}},[(_vm.currentOption.download_url)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.currentOption.nome_original))]),_c('a',{attrs:{"href":_vm.currentOption.download_url,"target":"_blank"}},[_vm._v(" Visualizar ")])]):_vm._e()]):_vm._e()])]):_vm._e()]):_vm._e()],1),_c('StepItemGraph',{attrs:{"item":_vm.item,"diagnosis":_vm.diagnosis}})],1),_c('b-row',[(!_vm.item.utiliza_opcoes || _vm.item.aguardando_resposta)?_c('b-col',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"md":"12"}},[_c('b-button',{staticStyle:{"background":"transparent","border":"none"}},[_c('span',{staticStyle:{"color":"var(--primary)","font-weight":"500","font-size":"14px"}},[_vm._v(" Resposta do cliente ")])]),_c('b-form-select',{style:(("width: 30%; border-color: " + (!_vm.item.resposta_cliente
            ? 'var(--blue)'
            : _vm.item.resposta_cliente == 1
            ? 'var(--green-later-leaf)'
            : [2, 4].includes(_vm.item.resposta_cliente)
            ? 'var(--yellow-light)'
            : _vm.item.resposta_cliente == 3 && 'var(--red-light)') + "; color: " + (!_vm.item.resposta_cliente
            ? 'var(--blue)'
            : _vm.item.resposta_cliente == 1
            ? 'var(--green-dark)'
            : [2, 4].includes(_vm.item.resposta_cliente)
            ? 'var(--crete)'
            : _vm.item.resposta_cliente == 3 && 'var(--roof-terracotta)') + "; background-color: " + (!_vm.item.resposta_cliente
            ? 'var(--white)'
            : _vm.item.resposta_cliente == 1
            ? 'var(--green-light)'
            : [2, 4].includes(_vm.item.resposta_cliente)
            ? 'var(--yellow-light-2)'
            : _vm.item.resposta_cliente == 3 && 'var(--pink-light)'))),attrs:{"options":_vm.statusStudy},on:{"change":function($event){return _vm.updateCustomerResponse()}},model:{value:(_vm.item.resposta_cliente),callback:function ($$v) {_vm.$set(_vm.item, "resposta_cliente", $$v)},expression:"item.resposta_cliente"}})],1):_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('ButtonEditComment',{staticClass:"mt-3",attrs:{"icon":"plus","title":((_vm.item.diagnostico_comentario &&
          _vm.item.diagnostico_comentario.comentario
            ? 'EDITAR'
            : 'ADICIONAR') + " COMENTÁRIO"),"openModalEditComment":function () { return _vm.openModalAddEditComment(); }}})],1),_c('b-col',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"md":"6"}},[_c('div',[(
            _vm.item.diagnostico_comentario_especialista &&
            _vm.item.diagnostico_comentario_especialista.comentario
          )?_c('b-button-group',{staticClass:"mt-3",staticStyle:{"padding":"6px 0"},on:{"click":function($event){return _vm.viewSpecialistComment()}}},[_c('b-button',{staticStyle:{"background":"var(--border-blue-light)","border-color":"var(--border-blue-light)"}},[_c('i',{staticClass:"fas fa-eye",staticStyle:{"font-size":"1rem"}})]),_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Ver comentário do especialista ")])],1):_vm._e()],1)])],1),_c('ModalViewComment',{attrs:{"title":"Comentário do especialista","isOpen":_vm.isOpenModalViewComment,"diagnosisComment":_vm.item.diagnostico_comentario_especialista},on:{"closeModal":function($event){return _vm.closeModalViewComment()}}}),_c('ModalAddEditComment',{attrs:{"isOpen":_vm.isOpenModalAddEditComment,"data":_vm.modalData},on:{"onSaveComment":function (diagnosisComment) { return _vm.onSaveComment(diagnosisComment); },"closeModal":function($event){return _vm.closeModalAddEditComment()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }