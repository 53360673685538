import { render, staticRenderFns } from "./StepViewItem.vue?vue&type=template&id=2dd75169&scoped=true&"
import script from "./StepViewItem.vue?vue&type=script&lang=js&"
export * from "./StepViewItem.vue?vue&type=script&lang=js&"
import style0 from "./StepViewItem.vue?vue&type=style&index=0&id=2dd75169&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd75169",
  null
  
)

export default component.exports