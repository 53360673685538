<template>
  <b-col
    :md="
      item.tipo_grafico === 3 ||
      item.id_campo === 14 ||
      item.titulo === 'Consórcio' ||
      item.titulo === 'Proteção do Patrimônio'
        ? '12'
        : '6'
    "
    class="graph-column"
    v-if="![1, 3].includes(item.tipo_tabela)"
  >
    <div v-if="item.tipo_grafico === 1">
      <highcharts :options="columnChart" />

      <table style="margin-top: 2rem" class="table graph-table">
        <tr>
          <th>Capital Segurado</th>
          <td
            v-for="option in item.diagnostico_produto_item_opcoes"
            :key="option.diagnostico_produto_item_opcao_id"
          >
            R$ {{ option.capital_segurado | decimal }}
          </td>
        </tr>
        <tr>
          <th>Seguradora</th>
          <td
            v-for="option in item.diagnostico_produto_item_opcoes"
            :key="option.diagnostico_produto_item_opcao_id"
          >
            {{ option.seguradora }}
          </td>
        </tr>
        <tr>
          <th>Prêmio</th>
          <td
            v-for="option in item.diagnostico_produto_item_opcoes"
            :key="option.diagnostico_produto_item_opcao_id"
          >
            R$ {{ option.premio_mensal | decimal }}
          </td>
        </tr>
      </table>
    </div>

    <b-table-simple v-if="item.tipo_grafico === 2">
      <b-tbody>
        <tr style="background: var(--product-life); color: var(--white)">
          <th>PLANO ATUAL</th>
          <th></th>
        </tr>
        <b-tr
          v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
          :key="tableItem.diagnostico_produto_item_tabela_item_id"
        >
          <b-th>{{ tableItem.coluna_titulo }}</b-th>
          <b-td>{{ tableItem.coluna_valor }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-col>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { cloneObject } from '@/helpers';

import {
  uploadProductItemOption,
  updateDiagnosisProductItemOpcoes
} from '@/services/requests/diagnosis';

export default {
  name: 'StepViewItemGraph',
  components: {
    highcharts: Chart
  },
  props: {
    item: Object,
    diagnosis: Object
  },
  data() {
    return {
      editMode: false,
      currentOptionIndex: 0
    };
  },
  methods: {
    series(necessidades, valor_atual, valor_necessario, sugeridos) {
      return [
        {
          name: 'Necessidades',
          data: [necessidades],
          color: 'var(--color-graph-1)',
          type: 'column'
        },
        {
          name: 'Valor atual',
          data: [valor_atual],
          color: 'var(--color-graph-2)',
          type: 'column'
        },
        {
          name: 'Valor necessário',
          data: [valor_necessario],
          color: 'var(--color-graph-3)',
          type: 'column'
        },
        {
          name: 'Valor Sugerido',
          data: [sugeridos],
          color: 'var(--color-graph-4)',
          type: 'column'
        }
      ];
    },

    async handleFileUpload(option, optionIndex) {
      if (!option.file) return;

      if (!option.diagnostico_produto_item_opcao_id) {
        const updatedItem = await this.saveQuotationRequest();
        const updatedOption =
          updatedItem.diagnostico_produto_item_opcoes[optionIndex];

        option.diagnostico_produto_item_opcao_id =
          updatedOption.diagnostico_produto_item_opcao_id;
      }

      const formData = new FormData();
      formData.append('file', option.file);

      const optionId = option.diagnostico_produto_item_opcao_id;

      uploadProductItemOption(optionId, option.file)
        .then((res) => {
          const optionIndex = this.item.diagnostico_produto_item_opcoes.findIndex(
            (option) => optionId === option.diagnostico_produto_item_opcao_id
          );

          const updatedOption = {
            ...cloneObject(option),
            ...res.data.result.data
          };

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            optionIndex,
            updatedOption
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleAddOption() {
      const option = {
        diagnostico_produto_item_opcao_id: null,
        seguradora: null,
        capital_segurado: 0,
        solucao: null,
        periodo_cobertura: null,
        periodo_pagamento: null,
        premio_mensal: 0,
        premio_anual: 0
      };

      this.item.diagnostico_produto_item_opcoes.push(option);
      this.currentOptionIndex =
        this.item.diagnostico_produto_item_opcoes.length - 1;
    },

    handleDeleteOption() {
      this.currentOptionIndex = null;

      const options = cloneObject(this.item.diagnostico_produto_item_opcoes);

      options.splice(this.currentOptionIndex, 1);

      this.$set(this.item, 'diagnostico_produto_item_opcoes', options);
    },

    saveQuotationRequest() {
      return new Promise((resolve, reject) => {
        const data = {
          diagnostico_produto_item_opcoes: cloneObject(
            this.item.diagnostico_produto_item_opcoes
          )
        };

        updateDiagnosisProductItemOpcoes(
          this.item.diagnostico_produto_item_id,
          data
        )
          .then((res) => {
            this.item.aguardando_resposta = true;
            this.$set(
              this.item,
              'diagnostico_produto_item_opcoes',
              res.data.result.data.diagnostico_produto_item_opcoes
            );
            this.editMode = false;

            resolve(res.data.result.data);
          })
          .catch((error) => {
            let errorMessage = 'Houve um erro ao salvar as opções';

            if (error.response) {
              if (
                error.response.data.statusCode &&
                error.response.data.statusCode !== 200
              ) {
                errorMessage = error.response.data.statusMessage;
              }

              if (
                error.response.data.message &&
                !error.response.data.message.diagnostico_produto_item_opcoes
              ) {
                errorMessage = 'Todos os valores são obrigatórios';
              }
            }

            alert(errorMessage);
            reject(error);
          });
      });
    },

    handleEditQuotation() {
      this.editMode = true;
      this.optionsBackup = cloneObject(
        this.item.diagnostico_produto_item_opcoes
      );
    },

    handleCancel(item) {
      this.editMode = false;
      item.diagnostico_produto_item_opcoes = [];
    },

    handleSaveQuotation() {
      this.saveQuotationRequest();
    }
  },
  computed: {
    columnChart() {
      const columnChart = {
        chart: {
          type: 'column'
        },

        title: {
          text: 'Capital segurado'
        },
        xAxis: {
          labels: {
            enabled: false
          }
        },
        yAxis: {
          title: '',
          min: 0
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(this.y);
              }
            },
            enableMouseTracking: true
          }
        },
        lang: {
          noData: 'Personalized no data message'
        },
        series: [
          {
            name: 'Valor cobertura',
            data: [],
            color: 'var(--color-graph-1)',
            type: 'column'
          },
          {
            name: 'Prêmio mensal',
            data: [],
            color: 'var(--color-graph-2)',
            type: 'column'
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  enabled: true
                }
              }
            }
          ]
        }
      };

      columnChart.series[0].data = this.item.diagnostico_produto_item_opcoes.map(
        (option) => parseFloat(option.capital_segurado)
      );
      columnChart.series[1].data = this.item.diagnostico_produto_item_opcoes.map(
        (option) => parseFloat(option.premio_mensal)
      );

      return columnChart;
    }
  }
};
</script>

<style lang="scss" scoped>
.graph-column {
  overflow-x: auto;
}

.graph-table th,
.graph-table td {
  font-size: 11px;
}
</style>
